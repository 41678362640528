import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: 'app-horizontalrow',
  templateUrl: './horizontalrow.component.html',
  styleUrls: ['./horizontalrow.component.css']
})
export class HorizontalrowComponent implements OnInit {
  @Input() content: any;
  @Input() sliderId: any;
  public default_lang = "";
  public htmlembade: any;

  constructor(public sanitizer: DomSanitizer, private cookieService: CookieService) {
    // this.default_lang = JSON.parse(this.cookieService.get('defaultlang'));
    this.default_lang = (this.cookieService.get("defaultlang").length > 0) ? JSON.parse(this.cookieService.get("defaultlang")) : '';
  }

  ngOnInit() {
  }
  openPageLink(link) {
    if (link !== 'false') {
      window.open(link, "_top");
    }
  }

}
