import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CartComponent } from './cart/cart.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { OrderComponent } from './order/order.component';
import { ProductsearchComponent } from './customcomps/productsearch/productsearch.component';
import { TestitComponent } from './testit/testit.component';

const routes: Routes = [
  {path: '404', component: NotfoundComponent},
  // { path: 'productdetails/:productid/:productslug', component: ProductdetailsComponent, },
  // { path: 'productdetails/:productid/:productslug/:language', component: ProductdetailsComponent },
  { path: 'productdetails/:productid', component: ProductdetailsComponent, },
  { path: 'productdetails/:productid/:language', component: ProductdetailsComponent },
  { path: 'order/:slug', component: OrderComponent, data: { title: 'Order'}},
  { path: 'checkout', component: CheckoutComponent, data: { title: 'Checkout'}},
  { path: 'checkout/:slug', component: CheckoutComponent, data: { title: 'Checkout'}},
  { path: 'cart', component: CartComponent, data: { title: 'Cart'}},
  { path: 'cart/:language', component: CartComponent, data: { title: 'Cart'}},
  { path: 'home', component: HomeComponent, data: { title: 'Home Page'}},
  { path: 'productsearch', component: ProductsearchComponent, data: { title: 'Search Results'}},
  { path: 'productsearch/:search', component: ProductsearchComponent, data: { title: 'Search Results'}},
  { path: 'productsearch/:search/:language', component: ProductsearchComponent, data: { title: 'Search Results'}},
  { path: 'testit', component: TestitComponent, data: { title: 'Test Page'}},
  { path: 'testit/:language', component: TestitComponent, data: { title: 'Test Page'}},
 

  { path: '', redirectTo: '/home', pathMatch: 'full' },
  // { path: '404', component: NotfoundComponent },
  { path: ':slug', component: HomeComponent, },
  { path: ':slug/:language', component: HomeComponent },
   { path: '**', redirectTo: '404' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
