import {Component, OnInit, Input} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare var jQuery: any;

@Component({
  selector: 'app-headerupperslider',
  templateUrl: './headerupperslider.component.html',
  styleUrls: ['./headerupperslider.component.scss']
})
export class HeaderuppersliderComponent implements OnInit {
  @Input() content: any;
  @Input() sliderContent: any;
  @Input() sliderId: any;
  public htmlembade: any;
  constructor(public sanitizer: DomSanitizer) {    
  }
  openPageLink(link) {
    if (link !== 'false') {
      window.open(link, "_top");
    }
  }


  ngOnInit() {
    // this.InitializeSlider();
  }
  ngAfterViewInit(){
  //ngAfterViewChecked(){
    this.InitializeSlider();
  }

  private InitializeSlider(){
    jQuery(".carousel").carousel({
      interval: 3000
    });

    jQuery('#bootstrap-touch-slider').bsTouchSlider();
    if ( jQuery('.carousel a.right').length > 0) {
      jQuery('.carousel a.right').trigger('click');
      jQuery('.carousel div.active').trigger('hover');
      jQuery('.carousel div.active').trigger('mouseout');
    }
  }

}
