
import { Subject } from 'rxjs';
import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";

import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import {
  HttpClient
} from "@angular/common/http";
import { environment } from "../environments/environment";
import { CookieService } from "ngx-cookie-service";

declare var jQuery: any;

@Injectable()
export class PageblocksService {
 
  private actionUrl = "https://www.ourglobalidea.com/rep/smeeshopapis";
  private dataObs$ = new Subject();
  public countydata = {};
  public result: Array<Object>;
  public language: String = "";
  public pagerul: any;
  public hostStr = window.location.host;
  public defaultlangGet: Array<Object>;


  getData() {
    return this.dataObs$;
  }
  updateData(data: any) {
    this.dataObs$.next(data);
  }
  setbackGround(data: any) {
    jQuery("body").css(data);
  }

  constructor(
    private http: HttpClient,
    private cookieService: CookieService) {
       if ((window.location.host.indexOf('localhost:') > -1) || (window.location.host.indexOf('ogi-main.glohtesting') > -1) || (window.location.host.indexOf('mycompany.glohtesting.com') > -1)){
        //this.actionUrl = 'https://ogi-main.glohtesting.com/rep/smeeshopapis';
          this.actionUrl = 'https://ourglobalidea.com/rep/smeeshopapis';
      }else{

      }
     }

  public getPageConfiguration(OwnerName: string = null, PageType: string = null) {
    if (environment.production === false) {
      OwnerName = "mycompany";
      // OwnerName = 'kokkinos';
    } else {
      OwnerName = window.location.host.split(".")[0] === "" ? "mycompany"
        : window.location.host.split(".")[0];
    }
    // alert( window.location.host );
    if (PageType == null) {
      PageType = "home";
    }

    let apiurl = "";
    if (this.cookieService.get("defaultlang").length > 0) {
      apiurl =
        this.actionUrl +
        "/getEshopDefaultBlocks/" +
        OwnerName +
        "/" +
        PageType +
        "/" +
        JSON.parse(this.cookieService.get("defaultlang"));
    } else {
      apiurl =
        this.actionUrl + "/getEshopDefaultBlocks/" + OwnerName + "/" + PageType;
    }
    const postdata = {
      hostStr: this.hostStr
    };
    return this.http.post(apiurl + ".json", postdata);
  }
  public getPageBlocks(OwnerName: string = null, PageType: string = null, orderdata: string = null,searchby: string = null) {
    const postdata = { hostStr: this.hostStr };
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName = window.location.host.split(".")[0] === "" ? "mycompany"
        : window.location.host.split(".")[0];
    }

    PageType = (PageType == null) ? "home" : PageType;
    let apiurl = "";
    if (this.cookieService.get("defaultlang").length > 0) {
      apiurl = this.actionUrl + "/getEshopConfigurations/" + OwnerName + "/" +
        PageType + "/" + JSON.parse(this.cookieService.get("defaultlang")) + "/" + (orderdata == null ? 'newest' : orderdata) ;
    } else {
      apiurl = this.actionUrl + "/getEshopConfigurations/" + OwnerName + "/" +
        PageType + "/" + null + "/" + (orderdata == null ? 'newest' : orderdata) ;
    }

    if(searchby != null && searchby !=''){
      apiurl= apiurl + "/" + searchby;
    }
    return this.http.post(apiurl + ".json", postdata);
  }
  public getProductDetails(ProductId: string = null, OwnerName: string = null, Language: string = null) {
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName =
        window.location.host.split(".")[0] === "" ? "mycompany" : window.location.host.split(".")[0];
    }
    if (Language == null) {
      console.log('what is this ' + this.cookieService.get("defaultlang"));
      Language = JSON.parse(this.cookieService.get("defaultlang"));
    }
    const postdata = {
      hostStr: this.hostStr
    };
    return this.http.post(this.actionUrl + "/getProductDetails/" + ProductId + "/" + OwnerName +
      "/" + Language + ".json", postdata);
  }


  public getCategorySearch(OwnerName: string = null, Language: string = null) {
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName =
        window.location.host.split(".")[0] === "" ? "mycompany" : window.location.host.split(".")[0];
    }
    if (Language == null) {
      console.log('what is this ' + this.cookieService.get("defaultlang"));
      Language = JSON.parse(this.cookieService.get("defaultlang"));
    }
    const postdata = {
      hostStr: this.hostStr
    };
    return this.http.post(this.actionUrl + "/getSearchConfig/" + OwnerName +
      "/" + Language + ".json", postdata);
  }

  // public sendEmail(maildata) {
  // return this.http.post(this.actionUrl + '/send_contactmail', maildata)
  //  .map(response => {
  //     console.log('Sending email was successfull', response);
  //      return response;
  //   })
  //   .catch(error => {
  //    console.log('Sending email got error', error);
  //    return Observable.throw(error);
  //  })
  // }

  sendEmail(formdata) {
    return this.http.post(this.actionUrl + "/send_contactmail.json", formdata);
  }

  //  public getShippingCost(cartItems: any , country: string = 'IN', OwnerName: string = null, Language: string = null) {
  //        if (environment.production === false) {
  //          OwnerName = 'mycompany';
  //        } else {
  //          OwnerName = ((window.location.host.split(".")[0] === "") ? "mycompany" : window.location.host.split(".")[0]);
  //        }
  //       //  if (Language == null) {
  //       //    Language = 'en';
  //       //  }
  //       if (Language == null) {
  //         Language = JSON.parse(this.cookieService.get('defaultlang'));
  //       }
  //       // const postData = Array('cart_items' => cartItems  );
  //        cartItems = {
  //          "cart_items": cartItems,
  //          "country": country,
  //          "OwnerName": OwnerName,
  //          "Language" : Language
  //      };
  //        return this.http.post(this.actionUrl + '/getShippingCost.json', cartItems);
  //  }

  public getshippingcost(
    totalcost: any,
    size: any,
    weight: any,
    country: string = "113",
    shipmentid: number = null,
    paymentmode: any = null,
    OwnerName: string = null,
    Language: string = null,
    orderModeId: any = null
  ) {
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName =
        window.location.host.split(".")[0] === ""
          ? "mycompany"
          : window.location.host.split(".")[0];
    }

    if (Language == null) {
      Language = JSON.parse(this.cookieService.get("defaultlang"));
    }
    const postdata = {
      hostStr: this.hostStr
    };
    return this.http.post(
      this.actionUrl + "/getShippingCost/" + OwnerName + "/" + country + "/" + totalcost +
      "/" + weight + "/" + size + "/" + shipmentid + "/" + paymentmode + "/" + Language + "/" + orderModeId +
      ".json", postdata
    );
  }

  // public getLocationDetails() {
  //   return this.http.get('https://ipinfo.io').map(response => response.json())
  //   .subscribe(result => this.result = result);
  //  // return this.http.get('https://ipinfo.io');
  //  }

  public getCheckoutConfigurations(
    county: string = null,
    OwnerName: string = null,
    Language: string = null
  ) {
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName =
        window.location.host.split(".")[0] === ""
          ? "mycompany"
          : window.location.host.split(".")[0];
    }

    if (Language == null) {
      Language = JSON.parse(this.cookieService.get("defaultlang"));
    }

    const postdata = {
      hostStr: this.hostStr
    };
    return this.http.post(
      this.actionUrl +
      "/getCheckoutConfigurations/" +
      OwnerName +
      "/" +
      county +
      "/" +
      Language +
      ".json",
      postdata
    );
  }

  validateCheckoutAddress(formdata, OwnerName: string = null) {
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName = window.location.host.split(".")[0] === "" ? "mycompany" : window.location.host.split(".")[0];
    }
    formdata.hostStr = this.hostStr;
    return this.http.post(
      this.actionUrl + "/validateCheckoutAddress/" + OwnerName + ".json",
      formdata
    );
  }
  placeorder(
    placeorderdata,
    OwnerName: string = null,
    Language: string = null
  ) {
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName =
        window.location.host.split(".")[0] === ""
          ? "mycompany"
          : window.location.host.split(".")[0];
    }
    if (Language == null) {
      Language = JSON.parse(this.cookieService.get("defaultlang"));
    }
    placeorderdata.hostStr = this.hostStr;
    return this.http.post(
      this.actionUrl +
      "/sendEmailOrders/" +
      OwnerName +
      "/" +
      Language +
      ".json",
      placeorderdata
    );
  }

  saveOrderdataBeforePlaceorder(placeorderdata, OwnerName: string = null) {
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName =
        window.location.host.split(".")[0] === ""
          ? "mycompany"
          : window.location.host.split(".")[0];
    }
    placeorderdata['hostStr']=this.hostStr;
    return this.http.post(
      this.actionUrl + "/saveOrderdataBeforePlaceorder/" + OwnerName + ".json",
      placeorderdata
    );
  }
  vivapaymentOrder(placeorderdata, OwnerName: string = null) {
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName =
        window.location.host.split(".")[0] === ""
          ? "mycompany"
          : window.location.host.split(".")[0];
    }
    placeorderdata['hostStr']=this.hostStr;
    console.log('placeorderdata',placeorderdata);
    return this.http.post(
      this.actionUrl + "/vivapaymentOrder/" + OwnerName + ".json",
      placeorderdata
    );
  }
  vivareturn(placeorderdata, OwnerName: string = null) {
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName =
        window.location.host.split(".")[0] === ""
          ? "mycompany"
          : window.location.host.split(".")[0];
    }
    placeorderdata['hostStr']=this.hostStr;
    return this.http.post(
      this.actionUrl + "/vivareturn/" + OwnerName + ".json",
      placeorderdata
    );
  }
  getStatelist(countrycode: string = null) {
    return this.http.get(
      this.actionUrl + "/getstatelist/" + countrycode + ".json"
    );
  }
  getCitylist(stateode: string = null) {
    return this.http.get(this.actionUrl + "/getcitylist/" + stateode + ".json");
  }
  applyCouponCode(
    couponcode: string = null,
    grandTotal: any = null,
    cartproducts: any,
    productTotalAmount: any = null,
    OwnerName: string = null
  ) {
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName = window.location.host.split(".")[0] === "" ? "mycompany" : window.location.host.split(".")[0];
    }


    const postdata = {
      couponcode: couponcode,
      grand_total: grandTotal,
      product_total_amount: productTotalAmount,
      cartproducts: cartproducts,
      hostStr: this.hostStr
    };
    return this.http.post(
      this.actionUrl + "/applyCouponCode/" + OwnerName + ".json",
      postdata
    );
  }
  applyCouponCode2(
    couponcode: string = null,
    grandTotal: any = null,
    cartproducts: any,
    productTotalAmount: any = null,
    couponcode2: string = null,
    OwnerName: string = null
  ) {
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName = window.location.host.split(".")[0] === "" ? "mycompany" : window.location.host.split(".")[0];
    }

    const postdata = {
      couponcode: couponcode,
      couponcode2: couponcode2,
      grand_total: grandTotal,
      product_total_amount: productTotalAmount,
      cartproducts: cartproducts,
      hostStr: this.hostStr
    };
    return this.http.post(
      this.actionUrl + "/applyCouponCode2/" + OwnerName + ".json",
      postdata
    );
  }

  validateApplyOgiDiscount(
    username: string = null,
    password: string = null,
    temp_onlytotal: any = null,
    temp_total_with_shipping: string = null
  ) {
    let OwnerName = "";
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName =
        window.location.host.split(".")[0] === ""
          ? "mycompany"
          : window.location.host.split(".")[0];
    }

    const ogiUserCredentials = {
      username: username,
      password: password,
      product_total_amount: temp_onlytotal,
      grand_total: temp_total_with_shipping,
      hostStr: this.hostStr
    };
    return this.http.post(
      this.actionUrl + "/validateApplyOgiDiscount" + "/" + OwnerName + ".json",
      ogiUserCredentials
    );
  }

  updateOrderStatus(orderdataid, status: any = null) {
    const postdata = {
      orderdataid: orderdataid,
      status: status
    };
    return this.http.post(this.actionUrl + "/updateOrderStatus.json", postdata);
  }
  getTranslatedTexts(username = null, Language = null) {

    if (environment.production === false) {
      username = "mycompany";
    } else {
      username =
        window.location.host.split(".")[0] === ""
          ? "mycompany"
          : window.location.host.split(".")[0];
    }
    if (Language == null) {
      Language = JSON.parse(this.cookieService.get("defaultlang"));
    }
    const postdata = { hostStr: this.hostStr };
    return this.http.post(
      this.actionUrl + "/getTranslatedTexts/" + username + "/" + Language + ".json", postdata
    );
  }
  getChatSettings(OwnerName: string = null, Language: any = null) {
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName = window.location.host.split(".")[0] === ""? "mycompany": window.location.host.split(".")[0];
    }

    if (Language == null) {
      Language = JSON.parse(this.cookieService.get("defaultlang"));
    }
    return this.http.get(
      this.actionUrl + "/getChatSettings/" + OwnerName + "/" + Language + ".json"
    );
  }
  sendContacOfflinetMail(formdata, OwnerName: string = null) {
    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName =
        window.location.host.split(".")[0] === ""
          ? "mycompany"
          : window.location.host.split(".")[0];
    }
    formdata['pageUrl'] = 'https://' + OwnerName + '.ogibiz.com/neweshops/home';
   // formdata['pageUrl'] = 'https://' + OwnerName + '.ogibiz.com/neweshops/home';
    formdata['username'] = OwnerName;
    formdata['hostStr'] = this.hostStr;
    return this.http.post(this.actionUrl + "/sendContacOfflinetMail.json", formdata);
  }
  getusernamebyurl() {
    const postdata = { hostStr: this.hostStr };
    return this.http.post(this.actionUrl + "/getUsernameByHost/" + ".json", postdata);
  }
  getSocailShareDetails(productid: any = null, OwnerName: string = null,
    language: string = null) {

    if (environment.production === false) {
      OwnerName = "mycompany";
    } else {
      OwnerName =
        window.location.host.split(".")[0] === ""
          ? "mycompany"
          : window.location.host.split(".")[0];
    }

    if (language == null) {
      language = JSON.parse(this.cookieService.get("defaultlang"));
    }

    const postdata = {
      productid: productid
    };
    return this.http.get("https://www.ourglobalidea.com/rep/smeeshops/getSocailShareDetails/" + productid);
  }
  getCompanyDefaultLang(username: any = null, language: any = null) {
    if (environment.production === false) {
      username = "mycompany";
    } else {
      username =
        window.location.host.split(".")[0] === ""
          ? "mycompany"
          : window.location.host.split(".")[0];
    }
    const postdata = {
      username: username,
      hostStr: this.hostStr
    };
    if (language != null && language != undefined) {
      return this.defaultlangGet['DefaultLang'].lang = language;
    } else {
      return this.http.post(this.actionUrl + "/getCompanyDefaultLang/" + username + '.json', postdata);
    }

  }
  getSearchData(searchtext: any = null, username: string = null, Language: string = null,orderby: string = null) {
    if (environment.production === false) {
      username = "mycompany";
    } else {
      username = window.location.host.split(".")[0] === "" ? "mycompany" : window.location.host.split(".")[0];
    }

    if (Language == null) {
      Language = JSON.parse(this.cookieService.get("defaultlang"));
    }
    const postdata = {
      username: username,
      hostStr: this.hostStr,
      searchtext: searchtext
    };
    return this.http.post(this.actionUrl + "/getSearchData/" + username + "/" + Language + "/"+ orderby +".json", postdata);
  }

  getSearchDataProducts(searchtext: any = null, username: string = null, Language: string = null,orderby: string = null) {
    if (environment.production === false) {
      username = "mycompany";
    } else {
      username = window.location.host.split(".")[0] === "" ? "mycompany" : window.location.host.split(".")[0];
    }

    if (Language == null) {
      Language = JSON.parse(this.cookieService.get("defaultlang"));
    }
    const postdata = {
      username: username,
      hostStr: this.hostStr,
      searchtext: searchtext
    };
    return this.http.post(this.actionUrl + "/getSearchDataProducts/" + username + "/" + Language + "/"+ orderby +".json", postdata);
  }

  getSearchSuggionData(username: string = null, PageType: string = null, searchKey,Language: string = null){
    if (Language == null) {
      Language = JSON.parse(this.cookieService.get("defaultlang"));
    }
    if (environment.production === false) {
      username = "mycompany";
    } else {
      username = window.location.host.split(".")[0] === "" ? "mycompany" : window.location.host.split(".")[0];
    }
    const postdata = {
      username: username,
      hostStr: this.hostStr,
      searchtext: searchKey,
      language:Language
    };
    return this.http.post(this.actionUrl + "/getSearchSuggionData" +".json", postdata);
  }

}
