import { Subscription } from "rxjs";
import "rxjs/add/operator/first";
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  Params,
  Routes,
  RoutesRecognized
} from "@angular/router";
import { PageblocksService } from "./pageblocks.service";
import { Component, Input } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../environments/environment";
import { Location } from "@angular/common";
import { Title, Meta } from "@angular/platform-browser";

import { filter } from "rxjs/operators";
// Import the application components and services.
declare var jQuery: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [PageblocksService]
})
export class AppComponent {
  routerSubscription: Subscription;
  blocksCompnent: any;
  public routerPar: any;
  public language: string;
  // tslint:disable-next-line:no-inferrable-types
  public language_set: boolean = false;
  public chatsettings: any;
  public link:any;
  public footerTypeIndex = 1;
  public footerDataIndex = 2;

  @Input() default_lang: String = "";
  @Input() default_lang_country: String = "";


  public isTestit: Boolean = false;
  username: any='mycompany';

  constructor(
    public _pageBlocks: PageblocksService,
    private router: Router,
    private cookieService: CookieService,
    public activatedRoute: ActivatedRoute,
    location: Location,
    private meta: Meta,
    private titleService: Title,
  ) {
    this.router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        // console.log(val.state.root.firstChild.routeConfig);
      if (val.state.root.firstChild.routeConfig.path === 'testit' || val.state.root.firstChild.routeConfig.path === 'testit/:language') {
        this.isTestit = true;
      } else {
        if (val.state.root.firstChild.params["language"] !== undefined) {
          this.language = val.state.root.firstChild.params["language"];
          this.cookieService.set(
            "defaultlang",
            JSON.stringify(this.language),
            1,
            "/"
          );
          this.LoadPageConfiguration();
        } else {
          this.LoadPageConfiguration();
        }
      }
      }
    });
  }

  // ============================== favicon set =======================

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    // this.resetFavicon();
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        jQuery(".navbar,.sidebarmobile").removeClass("openmobile");
        window.scrollTo(0, 0);        
      });


      this.activatedRoute.params.subscribe((params: Params) => {
        this.username = (params['username'] === '' || params['username'] === undefined) ? 'mycompany' : params['username'];

        this._pageBlocks.getPageBlocks(this.username, 'home', 'newest')
        .subscribe((data: any) => {
          if (data.Configuration.Page.details.meta.meta_title !== '' &&
          data.Configuration.Page.details.meta.meta_title !== undefined) {
            console.log('aaa',data);
            this.meta.addTag({ name: 'title', content: data.Configuration.Page.details.meta.meta_title });
            this.titleService.setTitle(data.Configuration.Page.details.meta.meta_title);
          }
        })
      });
  }

  // I reset the favicon to use the "default" item.
  public resetFavicon(): void {
   // console.log("Resetting favicon");
  }
  // I activate the favicon with the given name.
  public useFavicon(name: string): void {
    // console.log("Activating favicon now:", name);
    // Notice that we don't need to know anything about how the favicon is defined;
    // not URLs, no image types - just the identifier. All of the implementation
    // details have been defined at bootstrap time.
  }
  private setbackGround(data) {
    jQuery("body").css(data);
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  private LoadPageConfiguration() {
    this._pageBlocks
      .getPageConfiguration(this.username, "home")
      .subscribe((data: Object) => {
        this.blocksCompnent = data;
         console.log(this.blocksCompnent);
         console.log('app' , this.blocksCompnent.Configuration.Page.shop_configuration);
        // console.log(this.blocksCompnent.Configuration.Page.meta.meta_img);
        if (this.blocksCompnent.Configuration == null || this.blocksCompnent.Configuration === 'null') {
          this.router.navigateByUrl("/404");
          return false;
        }

        // if (this.homedataDetail.Configuration.Page.details.meta.meta_title !== '' &&
        //   this.homedataDetail.Configuration.Page.details.meta.meta_title !== undefined) {
        //     console.log(this.homedataDetail);
        //   this.meta.addTag({ name: 'title', content: this.homedataDetail.Configuration.Page.details.meta.meta_title });
        //   this.titleService.setTitle(this.homedataDetail.Configuration.Page.details.meta.meta_title);
        // }

        if (this.blocksCompnent.Configuration.Page.meta !== undefined) {
          if (this.blocksCompnent.Configuration.Page.meta.meta_img !== "" &&
            this.blocksCompnent.Configuration.Page.meta.meta_img !== undefined &&
            this.blocksCompnent.Configuration.Page.meta.meta_img !== null &&
            this.blocksCompnent.Configuration.Page.meta.meta_img !== 'null') {
            this.meta.removeTag("name='og:image'");
            this.meta.addTag({name: "og:image", content: this.blocksCompnent.Configuration.Page.meta.meta_img.replace('www.', '')});
            

            // ==============fav icon =================
            let link = document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = this.blocksCompnent.Configuration.Page.meta.meta_img;
            document.getElementsByTagName('head')[0].appendChild(link);
            // ============== fav icon end ============
          }
        }
        // if (this.blocksCompnent.Configuration.Page.design !== null || this.blocksCompnent.Configuration.Page.design !== 'null') {
        //   // ======================== get chat settings ===========================
        //    this._pageBlocks.getChatSettings('mycompany').subscribe((chatdata: Object) => {
        //     this.chatsettings = chatdata;
        //    });
        //   // ======================== end get chat settings =======================
        // }
        // if ( this.cookieService.get('defaultlang').length <= 0 || !this.is_sameLang) {
        this.blocksCompnent.Configuration.DefaultBlocks.block.filter((item,index) => {
          if (item.block.blockname === "menu") {
            if (this.cookieService.get("defaultlang").length > 0) {
              item.language.forEach(langdata => {
                if (
                  langdata.languages.short_code ===
                  JSON.parse(this.cookieService.get("defaultlang"))
                ) {
                  this.default_lang = langdata.languages.short_code;
                  this.default_lang_country = langdata.languages.countryCode;
                  this.language_set = true;
                }
              });
            } else {
              if (!this.language_set) {
                this.default_lang = item.language[0].languages.short_code;
                this.default_lang_country =
                  item.language[0].languages.countryCode;
              }
            }
          }
          if (item.block.blockname === "footer") {
            this.footerTypeIndex = index;
           if( item.fields.footer_type === "block"){
            this.footerDataIndex = this.footerTypeIndex +  1;
           }
          }
        });

        this.cookieService.set(
          "defaultlang",
          JSON.stringify(this.default_lang),
          1,
          "/"
        );
        this.cookieService.set(
          "defaultlangcountry",
          JSON.stringify(this.default_lang_country),
          1,
          "/"
        );
      });
  }

  public changLang(lang: String, code_country: String) {
    lang = lang.toLowerCase();
    let currenturl = this.router.url;
    // tslint:disable-next-line:prefer-const
    let isNoLang = currenturl.lastIndexOf("/");
    if (this.default_lang !== lang) {
      this.default_lang = lang;
      this.default_lang_country = code_country;
      if (isNoLang > 0) {
        currenturl = currenturl.substring(0, currenturl.length - 3);
      }
      this.cookieService.set(
        "defaultlang",
        JSON.stringify(this.default_lang),
        1,
        "/"
      );
      this.cookieService.set(
        "defaultlangcountry",
        JSON.stringify(this.default_lang_country),
        1,
        "/"
      );
      // alert(environment.production);  alert(lang);
      if (environment.production === false) {
        location.href = currenturl + "/" + lang;
      } else {
        // if (window.location.href.indexOf("neweshops") > -1) {
        location.href = "." + currenturl + "/" + lang;
        // }
      }
    }
  }
  public checkPageprotection(page_password, pagedata) {
    if (page_password.length <= 0) {
      alert("Please proviede passwod !");
      return false;
    } else if (page_password === pagedata.page_password) {
      this.cookieService.set(
        "pageid_" + pagedata.page_id,
        JSON.stringify(page_password)
      );
      return true;
    } else {
      alert("Password missmatched !");
      return false;
    }
  }
}
