import { Component, OnInit, Input, Output } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-gallerymodal',
  templateUrl: './gallerymodal.component.html',
  styleUrls: ['./gallerymodal.component.scss']
})
export class GallerymodalComponent implements OnInit {

  @Input() selectedGalleryImage: any;
  @Input() selectedGalleryTitle: any;
  @Input() galleryid: any;
  @Input() currentModalImgCounter: any;
  @Input() captonDisplayStatus: any;

  public nextImg: any;
  public showModalLoader = false;
  public imageListingType:any  = 'gal_list_';
  public imageEachItem:any = 'gal_listitem';
  public hideshowCaptionText = 'show';

  constructor() { }

  ngOnInit() {
    //console.log(this.selectedGalleryTitle);
    this.hideshowCaptionText = (this.captonDisplayStatus == 'yes' )?'':'hide';
  }
  closeGalleryModel() {
    $('.' + this.galleryid).modal('hide');
  }
  navigateNextImg() {   
    
    this.imageListingType = 'gal_list_';
    this.imageEachItem = 'gal_listitem';
    if ($('div#' + this.galleryid).hasClass('gal_img_randomgrid')) {
      this.imageListingType = 'gal_img_randomgrid_';
      this.imageEachItem = 'gal_randomgriditem';
    }
    
    const galleryImgTotalCount = $('.'+this.imageListingType + this.galleryid + ' .gal_img').length;
    this.currentModalImgCounter = this.currentModalImgCounter + + 1;
    this.nextImg = $('.'+ this.imageListingType + this.galleryid).find('.gal_img' + this.currentModalImgCounter).attr('src');
    this.selectedGalleryImage = this.nextImg;
    this.selectedGalleryTitle =
      $('.' + this.imageListingType + this.galleryid).find('.gal_img' + this.currentModalImgCounter).closest('.gal_img ').next('.captionStyle').text();
    this.hideshowCaptionText = (this.captonDisplayStatus == 'yes'?((this.selectedGalleryTitle.length <= 0 )?'hide':''):'hide');
    // console.log(this.selectedGalleryTitle );
    // console.log(this.hideshowCaptionText );
    $('.' + this.galleryid).find('.next').show();
    if ((this.currentModalImgCounter + 1) === galleryImgTotalCount) {
      $('.' + this.galleryid).find('.next').hide();
    } else {
      $('.' + this.galleryid).find('.prev').show();
    }
    
  }
  navigatePrevImg() {   
    
    this.imageListingType = 'gal_list_';
    this.imageEachItem = 'gal_listitem';
    if ($('div#' + this.galleryid).hasClass('gal_img_randomgrid')) {
      this.imageListingType = 'gal_img_randomgrid_';
      this.imageEachItem = 'gal_randomgriditem';
    }


    this.currentModalImgCounter = this.currentModalImgCounter - 1;
    this.nextImg = $('.'+ this.imageListingType + this.galleryid).find('.gal_img' + this.currentModalImgCounter).attr('src');
    this.selectedGalleryImage = this.nextImg;
    this.selectedGalleryTitle =
    //$('.gal_list_' + this.galleryid).find('.gal_img' + this.currentModalImgCounter).closest('.gal_img').next('.captionStyle').text();
    $('.'+this.imageListingType + this.galleryid).find('.gal_img' + this.currentModalImgCounter).closest('.gal_img').next('.captionStyle').text();
    this.hideshowCaptionText = (this.captonDisplayStatus == 'yes'?((this.selectedGalleryTitle.length <= 0 )?'hide':''):'hide');
  //   console.log(this.selectedGalleryTitle );
  //  console.log(this.hideshowCaptionText );
    $('.' + this.galleryid).find('.prev').show();
    if ((this.currentModalImgCounter - 1) > -1) {
      $('.' + this.galleryid).find('.next').show();
    } else {
      $('.' + this.galleryid).find('.prev').hide();
    }
  }
}
