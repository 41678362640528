import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from "ngx-cookie-service";
declare var $: any;

@Component({
  selector: 'app-verticalcolumn',
  templateUrl: './verticalcolumn.component.html',
  styleUrls: ['./verticalcolumn.component.scss']
})
export class VerticalcolumnComponent implements OnInit {
  @Input() content: any;
  @Input() sliderId: any;
  public default_lang = "";

  public htmlembade: any;
  constructor(public router: Router, public sanitizer: DomSanitizer, private cookieService: CookieService) {
    // this.default_lang = JSON.parse(this.cookieService.get('defaultlang'));
    this.default_lang = (this.cookieService.get("defaultlang").length > 0) ? JSON.parse(this.cookieService.get("defaultlang")) : '';
  }

  ngOnInit() {
    // $('.vertical_image_area iframe').html('<div>asdfasd</div>');
  }



  openPageLink(link) {
    if (link !== 'false') {
      window.open(link, "_top");
    }
  }
  frameshape() {
    // alert('hi');
  }



}
