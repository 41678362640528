import { PageblocksService } from "../pageblocks.service";
// import { FormGroup, FormControl, FormArray, Validators, NgForm } from '@angular/forms';
import { Component, OnInit, Input, NgModule } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { AsyncPipe } from "@angular/common";
import { Http } from "@angular/http";
import { environment } from "../../environments/environment";
import { DomSanitizer, Meta, Title } from "@angular/platform-browser";

declare var jQuery: any;
@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"]
})
export class CheckoutComponent implements OnInit {
  @Input() checkoutconfig: any;
  @Input() slug: string;

  // public myForm: FormGroup;
  public loading: boolean;
  public product_total_amount: any = 0;
  public pricecurrency: any;
  public shippingdata: Promise<any>;
  products: Promise<any>;

  public countrycode: string;
 // public countrycode: string = 'IN';  // REMOVE THIS LINE WHILE MAKING LIVE
  public checkoutprice: number;
  public checkoutsize: number;
  public checkoutweight: number;
  public locationdata = {};
  public shippinglock: string;
  public paymentlock: string;
  public finalsubmitlock: string;
  public choosen_paymentmode: string;
  public shipmentid: number;
  public appliedCoupon: String = "";
  public appliedCoupon2: String = "";
  public is_coupon_enabled: Boolean = true;
  public is_valid_coupon: Boolean = false;
  public is_valid_coupon2: Boolean = false;
  public coupon_apply_msg: String = "";
  public coupon_apply_msg2: String = "";
  public coupon_discount_amt: any = "0.00";

  public ogi_discount_applied: Boolean = false;
  public ogi_discount_message: String = "";
  public ogi_discount_false_msg: String = "";
  public ogi_discount_amount: any = "0.00";
  public total_discount: any = "0.00";

  public productsdata = {};
  public addressdata = {};
  public shippingmethoddata = {};
  public productsOrderdata = {};
  // public slug: string;
  public usern: string;
  public username: string;
  public hosturl: string;
  public checkoutdataDetail: any;
  public paypalReturnStr = 
  ((window.location.host.indexOf('localhost:') > -1) || (window.location.host.indexOf('ogi-main.glohtesting') > -1) || (window.location.host.indexOf('mycompany.glohtesting.com') > -1))?
    'https://ogi-main.glohtesting.com/rep/smeeshopapis/paypalreturn/'
  :'https://www.ourglobalidea.com/rep/smeeshopapis/paypalreturn/'
  public orderdataid: string;
  public orderbuttonText = "order";
  public orderModeId: any;
  public statedata: any;
  public showaddress_loadder: Boolean = false;
  public shipping_loadder: Boolean = false;
  public payment_loadder: Boolean = false;
  public errormessage: String = "";
  public paypal_order_to;
  public set_language: string = JSON.parse(
    this.cookieService.get("defaultlang")
  );
  public ogiUsername: String = "";
  public ogiPassword: String = "";
  public final_grand_total: any = "";
  public final_discount_message = "";
  public manageTexts: any;
  public grandTotalInnitial: any;
  public couponcodeInnitial: any;
  public disableFirstCoupon: any = false;
  public isTermsConditinoChecked: any = false;
  public showShippingMsg: any = false;
  public total_cart_product_count: any = 0;



  // =================== shipping variable values for shiping address in payapl ===========
  public ship_first_name: any;
  public ship_email: any;
  public ship_country: any;
  public ship_state: any;
  public ship_city: any;
  public ship_address1: any;
  public ship_zip: any;
  loginDiscount: boolean;
  // =======================================================================================

  constructor(
    public ru: Router,
    public activatedRoute: ActivatedRoute,
    public service: PageblocksService,
    private cookieService: CookieService,
    private _http: Http,
    public sanitizer: DomSanitizer,
    private meta: Meta,
    private titleService: Title,
  ) {
    this.loading = true;
    const routerPar = this.activatedRoute.params.subscribe((params: Params) => {
      this.cookieService.delete("applied_coupon", "/");
      this.slug =
        params["slug"] === "" || params["slug"] === undefined
          ? null
          : params["slug"];

      this.username = (params['username'] === '' || params['username'] === undefined) ? 'mycompany' : params['username'];

      // if ( jQuery.trim(params['slug']) === 'success' ||  jQuery.trim(params['slug']) === 'cancel') {
      //   this.cookieService.delete('Cart_items');
      // }
      // setTimeout(function() {
      //   if ( jQuery.trim(params['slug']) === 'success') {
      //     jQuery(".cartCount").html("(0)");
      //   }
      // },3000);
      // this.ru.navigateByUrl('/home');
    });
  }

  ngOnInit() {
    this.checkoutconfig = [];
    this.loading = false;
    this.shippinglock = this.paymentlock = this.finalsubmitlock = "hide";

    if (environment.production === false) {
      this.usern = "mycompany";
    } else {
      if (window.location.host.split(".")[0] === "") {
        this.usern = "mycompoany";
      } else {
        this.service.getusernamebyurl().subscribe((hostdata: Object) => {
          this.usern = hostdata["hostDetails"];
        });
      }
    }

    this.service.getTranslatedTexts(this.usern).subscribe((tx: Object) => {
      this.manageTexts = tx["CartDetailsInfo"].translatedTexts;
      if (tx["CartDetailsInfo"].Page.design !== null) {
        this.service.setbackGround(
          tx["CartDetailsInfo"].Page.design[".page_background .page_bg"]
        );
      }
    });

    if (this.slug === "cancel") {
      this.loading = true;
      this.service
        .updateOrderStatus(this.cookieService.get("orderdataid"), 2)
        .subscribe(resdata => {
          this.cookieService.delete("orderdataid", "/");
        });
      this.cookieService.delete("paypal_order", "/");
      this.cookieService.delete("applied_coupon", "/");
      this.loading = false;
    } else if (this.slug === "success") {
      const urlParams = new URLSearchParams(window.location.search);
      const VivaorderId = urlParams.get('s');
      const VivatxId = urlParams.get('t');
      const lang = JSON.parse(
        this.cookieService.get("defaultlang")
      );
      console.log('l',lang);
       //viva
       this.productsOrderdata['oid']=VivaorderId;
       this.productsOrderdata['tid']=VivatxId;
       this.productsOrderdata['lang']=lang;
       this.service
        .vivareturn(this.productsOrderdata)
        .subscribe((res: any) => {
        });

      this.loading = true;
     // this.cookieService.delete("Cart_items", "/");
      localStorage.removeItem('Cart_items');
      this.cookieService.delete("paypal_order", "/");
      this.cookieService.delete("orderdataid", "/");
      this.cookieService.delete("applied_coupon", "/");
      // this.service.saveorderdetails( this.cookieService.get('orderdataid'), 'success').subscribe(
      //   resdata => {
      // this.cookieService.delete('orderdataid', '/');
      //     this.loading = false;
      // });
      
      this.loading = false;
    } else {
      //this.countrycode = 'IN';    // remove this line while live
      this.hosturl = window.location.host;
      this.products = this.getCartProducts();

        // if(this.countrycode !== undefined) {
        //   this.getCheckoutConfig(this.countrycode);
        // }else {
        //   this.getCheckoutConfig(this.countrycode);
        // }

      this.getLocationDetails().then((loc: any) => {    // unblock these 3 linss and block above 5 lines for live
        this.getCheckoutConfig(this.countrycode);
      });
    }
    this.loadData();
  }
  loadData(orderdata: string = 'newest',searchby: any = null) {
    this.service.getPageBlocks(this.username, 'checkout', orderdata,searchby)
      .subscribe((data: Object) => {
        console.log(data);
        
       
        this.checkoutdataDetail = data;
        if(this.checkoutdataDetail.Configuration.DiscountConfiguration==0){
        this.loginDiscount=false;
        }else{
          this.loginDiscount=true;
        }
        if(this.checkoutdataDetail.Configuration.Page.details == undefined){
          this.service.getPageBlocks(this.username, 'home', orderdata,searchby)
          .subscribe((edata: Object) => {
            this.checkoutdataDetail = edata;
            this.meta.removeTag("name='title'");
            this.meta.addTag({ name: 'title', content: this.checkoutdataDetail.Configuration.Page.details.meta.meta_title });
            this.titleService.setTitle(this.checkoutdataDetail.Configuration.Page.details.meta.meta_title);
          });
        }
        if(this.checkoutdataDetail.Configuration.Page.details !== undefined){
          this.meta.removeTag("name='title'");
          this.meta.addTag({ name: 'title', content: this.checkoutdataDetail.Configuration.Page.details.meta.meta_title });
          this.titleService.setTitle(this.checkoutdataDetail.Configuration.Page.details.meta.meta_title);
        }
        // console.log('meta_title',this.checkoutdataDetail.Configuration.Page.details.meta);
    });
  }

  public getCheckoutConfig(county) {
    return this.service
      .getCheckoutConfigurations(county)
      .subscribe((configdata: Object) => {
        this.checkoutconfig = configdata["checkoutConfigurations"];
        this.manageTexts = configdata["checkoutConfigurations"].translatedTexts;
        console.log("checkoutconfig", this.checkoutconfig);
        // console.log("checkout config", this.checkoutconfig);
        // console.log(
        //   "shipped_payment_mode",
        //   this.checkoutconfig.shipped_payment_mode
        // );
        this.checkoutconfig.shipped_payment_mode.forEach(payMode => {
          if (payMode.ordermode_id === "2") {
            this.paypal_order_to = payMode.order_to;
          }
        });
        // if (this.checkoutconfig.shipped_payment_mode.length > 1) {
        //   this.paypal_order_to = this.checkoutconfig.shipped_payment_mode[1].order_to;
        // }
      });
    // return Promise.resolve({
    //     checkoutconfig: checkoutconfig
    // });
  }
  private getCartProducts(): Promise<any> {
    const cookieKey = "Cart_items";
    // const existingcartprod = this.cookieService.check(cookieKey)
    //   ? JSON.parse(this.cookieService.get(cookieKey))
    //   : [];
    const existingcartprod =   (localStorage.getItem(cookieKey) != null) 
      ? JSON.parse(localStorage.getItem(cookieKey))
      : []; 
    let checkoutprice = 0;
    let checkoutweight = 0;
    let checkoutsize = 0;
    let pricecurrency = "USD";
    
    if (existingcartprod.length > 0) {
      this.pricecurrency = pricecurrency = existingcartprod[0].currency;
    } else {
      this.ru.navigateByUrl("/cart");
    }

    this.total_cart_product_count = 0;
    const products = existingcartprod.map(extractedproduct => {
      console.log('existingcartprod'+ extractedproduct);
      this.service
        .getProductDetails(extractedproduct.productid, null)
        .toPromise()
        .then((prod: any) => {
          const productdetails = prod.Configuration.ProductDetails;
          this.pricecurrency = productdetails.currency_code;
          if (extractedproduct.productid === productdetails.id) {
            extractedproduct.name = productdetails.name;
            extractedproduct.slug = productdetails.product_slug;
            extractedproduct.price = productdetails.price;
            extractedproduct.priceWithVariant =
              extractedproduct.total_price / extractedproduct.quantity;
            // this.productsdata = extractedproduct;
          }
        });
      this.checkoutprice = checkoutprice = checkoutprice + +extractedproduct.total_price;
     // this.checkoutweight = checkoutweight + +(extractedproduct.weight * extractedproduct.quantity);
     // this.checkoutsize = checkoutsize + +(extractedproduct.size * extractedproduct.quantity);
      
      this.checkoutweight = checkoutweight = checkoutweight + +(extractedproduct.weight * extractedproduct.quantity);
      this.checkoutsize = checkoutsize = checkoutsize + +(extractedproduct.size * extractedproduct.quantity);
      this.product_total_amount = checkoutprice;
      this.total_cart_product_count = Number(this.total_cart_product_count) + Number(extractedproduct.quantity);
      return extractedproduct;
    });
    this.productsdata = products;

    // this.countrycode = 'IN';  // REMOVE IN LIVE 

     console.log('checkoutweight'+ this.checkoutweight);
     console.log('checkoutsize'+ this.checkoutsize);
    if (this.countrycode === undefined) {
      console.log('en');
      this.getLocationDetails().then(Response => {
        this.locationdata = Response;
        this.shippingdata = this.getshipping(
          checkoutprice,
          this.checkoutsize,
          this.checkoutweight,
          this.countrycode
        );
        //   .then((resdata) => {
        //     this.final_grand_total = resdata.shippinginfo.total_with_shipping;
        //  });
      });
    }
    if (this.countrycode !== undefined) {
      this.shippingdata = this.getshipping(
        checkoutprice,
        this.checkoutsize,
        this.checkoutweight,
        this.countrycode
      );
    }
    return Promise.resolve({
      products: products,
      checkoutprice: checkoutprice,
      currency: pricecurrency,
      checkoutweight: checkoutweight,
      checkoutsize: checkoutsize
    });
  }
  private getshipping(
    checkoutprice,
    checkoutsize,
    checkoutweight,
    countrycodeStr,
    shipmentid: any = null,
    ordermode: any = null,
    ordermodeid: any = null
  ): Promise<any> {
    return this.service
      .getshippingcost(
        checkoutprice,
        checkoutsize,
        checkoutweight,
        countrycodeStr,
        shipmentid,
        ordermode,
        null,
        null,
        ordermodeid
      )
      .toPromise();
  }
  getLocationDetails() {
    return this._http
      .get("https://ipinfo.io")
      .toPromise()
      .then(response => {
        this.countrycode = response.json().country;
        return { location: response.json() };
      });
  }

  onCheckoutAddressSubmit(formdata) {
    // this.ship_first_name = formdata.value.name;
    // this.ship_email = formdata.value.email;
    // this.ship_country = formdata.value.country;
    // // this.ship_state = formdata.value.state;
    // // this.ship_city = formdata.value.city;
    // this.ship_address1 = formdata.value.address;
    // this.ship_zip = formdata.value.zip;
    console.log(this.shippingdata["__zone_symbol__value"].shippinginfo);
    if (!this.shippingdata["__zone_symbol__value"].shippinginfo.shipping_available) {
      this.showShippingMsg = true;
      return false;
    } else {
      this.showShippingMsg = false;
      console.log('abc');
      this.showaddress_loadder = true;
      this.addressdata = formdata.value;
      this.service
        .validateCheckoutAddress(formdata.value)
        .subscribe((res: Object) => {
          this.showaddress_loadder = false;
          if (res["shippingAddress"].error) {
            this.shippinglock = "hide";
            this.finalsubmitlock = "hide";
            jQuery("#" + res["shippingAddress"].field)
              .get(0)
              .scrollIntoView();
            jQuery("#" + res["shippingAddress"].field).focus();

            if (res["shippingAddress"].error_field_type === 'custom_field') {
              Object.keys(
                this.checkoutconfig.shipped_address
              ).forEach((keyV, checkoutaddressData) => {
                this.checkoutconfig.shipped_address[keyV].error = 0;
              });
              Object.keys(
                this.checkoutconfig.checkout_customfields_data
              ).forEach((keyvalue, checkoutaddressData) => {
                this.checkoutconfig.checkout_customfields_data[keyvalue].error = 0;
                if (
                  this.checkoutconfig.checkout_customfields_data[keyvalue].field.replace(' ', '_') ===
                  res["shippingAddress"].field
                ) {
                  this.checkoutconfig.checkout_customfields_data[keyvalue].error = 1;
                  this.errormessage = res["shippingAddress"].msg;
                }
              });
            } else {
              // === custom fields error message hide ==========
              Object.keys(
                this.checkoutconfig.checkout_customfields_data
              ).forEach((keyVal, customFData) => {
                this.checkoutconfig.checkout_customfields_data[keyVal].error = 0;
                // === end custom fields error message show ======
              });
              Object.keys(
                this.checkoutconfig.shipped_address
              ).forEach((keyvalue, checkoutaddressData) => {
                this.checkoutconfig.shipped_address[keyvalue].error = 0;
                if (
                  this.checkoutconfig.shipped_address[keyvalue].field ===
                  res["shippingAddress"].field
                ) {
                  this.checkoutconfig.shipped_address[keyvalue].error = 1;
                  this.errormessage = res["shippingAddress"].msg;
                }
              });
            }
          } else {
            if (
              this.shippingdata["__zone_symbol__value"].shippinginfo
                .hide_shipping_options
            ) {
              this.paymentlock = "show";
            } else {
              this.shippinglock = "show";
            }
            //  this.shippinglock = "show";
          }
        });
    }




  }
  onCheckoutShippingMehods(formShippingMethods) {
    this.shipping_loadder = true;
    if (this.shipmentid === undefined) {
      const asyncshippingdata = this.generateArray(this.shippingdata);
      this.shipmentid = asyncshippingdata[1].shippinginfo.shipment_option.id;
    }
    formShippingMethods.value.selectedShipment = this.shipmentid;
    this.shippingmethoddata = formShippingMethods.value;
    // setting in global veribale for final post and creeating shippingform data before it
    this.paymentlock = "show";
    this.shipping_loadder = false;
  }
  onCheckboxChange(eventdata: any) {
    this.finalsubmitlock = "hide";
    // console.log('eventdata' + eventdata);
    // console.log('choosen_paymentmode' + this.choosen_paymentmode);
    if (
      eventdata === true &&
      this.choosen_paymentmode !== undefined &&
      this.choosen_paymentmode !== null
    ) {
      this.finalsubmitlock = "show";
    }
    this.isTermsConditinoChecked = eventdata === true ? true : false;
  }
  updateShipping(checkoutprice, shipmentid: any = null): Promise<any> {
    this.service
      .getStatelist(this.countrycode)
      .subscribe((stateInfo: Object) => {
        this.statedata = stateInfo["statelist"];
        this.checkoutconfig["shipped_address"].map((checkoutcon, key) => {
          if (checkoutcon.field === "state") {
            checkoutcon.statelist = this.statedata;
          }
          if (checkoutcon.field === "city") {
            checkoutcon.citylist = [];
          }
        });
      });

    this.shipmentid = shipmentid;
    this.choosen_paymentmode =
    this.choosen_paymentmode != null ? this.choosen_paymentmode : null;
      console.log('checkoutsize'+ this.checkoutweight);
      console.log('checkoutweight'+ this.checkoutweight);
    this.shippingdata = this.getshipping(
      checkoutprice,
      this.checkoutsize,
      this.checkoutweight,
      this.countrycode,
      shipmentid,
      this.choosen_paymentmode
    ).then(resdata => {
      this.shippingdata = new Promise(resolve => {
        return resolve(resdata);
      });

      const applied_coupon = "applied_coupon";
      if (
        this.cookieService.check(applied_coupon) &&
        JSON.parse(this.cookieService.get(applied_coupon)).length > 0
      ) {
        this.validateCouponCode(
          JSON.parse(this.cookieService.get("applied_coupon")),
          true
        );
      } else {
        if (
          this.ogiUsername.length > 0 &&
          this.ogiPassword.length > 0 &&
          JSON.parse(this.cookieService.get(applied_coupon)).length <= 0
        ) {
          this.validateOgiUser(
            this.ogiUsername,
            this.ogiPassword,
            resdata.shippinginfo.total_with_shipping
          );
        }
      }
    });
    return Promise.resolve({
      checkoutprice: checkoutprice,
      checkoutweight: this.checkoutweight,
      checkoutsize: this.checkoutsize
    });
  }
  updateShippingByCountry(checkoutprice, countrycode) {
    const asyncshippingdata = this.generateArray(this.shippingdata);
    this.countrycode = countrycode;
    this.updateShipping(
      checkoutprice,
      asyncshippingdata[1].shippinginfo.shipment_option.id
    );

    // this.service.getStatelist(this.countrycode).subscribe((data: Object) => {
    //   this.statedata = data;
    //   console.log(this.statedata);
    // });
  }
  paymentmodeSelect(ordermode, oredermodeId) {
    this.orderModeId = oredermodeId;
    // for (const paymentMode of this.checkoutconfig.shipped_payment_mode) {
    //   if (paymentMode.ordermode_id === ordermode) {
    //     this.orderbuttonText = paymentMode.payment_button_name;
    //   }
    // }
    this.choosen_paymentmode = ordermode;
    this.shippingdata = this.getshipping(
      this.checkoutprice,
      this.checkoutsize,
      this.checkoutweight,
      this.countrycode,
      this.shipmentid,
      ordermode,
      oredermodeId
    ).then(resdata => {
      this.shippingdata = new Promise(resolve => {
        return resolve(resdata);
      });
      const applied_coupon = "applied_coupon";
      if (
        this.cookieService.check(applied_coupon) &&
        JSON.parse(this.cookieService.get(applied_coupon)).length > 0
      ) {
        this.validateCouponCode(
          JSON.parse(this.cookieService.get("applied_coupon")),
          true
        );
      } else {
        if (
          this.ogiUsername.length > 0 &&
          this.ogiPassword.length > 0 &&
          JSON.parse(this.cookieService.get(applied_coupon)).length <= 0
        ) {
          this.validateOgiUser(
            this.ogiUsername,
            this.ogiPassword,
            resdata.shippinginfo.total_with_shipping
          );
        }
      }
      this.validateTermsConditionStatus();
      // if (this.ogiUsername.length > 0 && this.ogiPassword.length > 0) {
      //   this.validateOgiUser(this.ogiUsername, this.ogiPassword, resdata.shippinginfo.total_with_shipping);
      // }
    });
  }
  placeorder(paymentdata, formCheckoutAddress) {
    this.payment_loadder = true;
    const placeorderdata = {};
    placeorderdata["language"] = JSON.parse(
      this.cookieService.get("defaultlang")
    );

    if (this.finalsubmitlock === "show" && this.choosen_paymentmode !== null) {
      placeorderdata["countrycode"] = this.countrycode;
      placeorderdata["checkoutprice"] = this.checkoutprice;
      placeorderdata["final_total"] = this.shippingdata[
        "__zone_symbol__value"
      ].shippinginfo.total_with_shipping;

      placeorderdata["productsdata"] = this.productsdata;
      placeorderdata["addressdata"] = formCheckoutAddress.value;
      placeorderdata["shippingmethoddata"] = this.shippingmethoddata;
      placeorderdata["paymentdata"] = paymentdata.value;
      placeorderdata["couponcode"] = this.cookieService.check("applied_coupon")
        ? JSON.parse(this.cookieService.get("applied_coupon"))
        : null;
      placeorderdata["coupon_discount"] = this.coupon_discount_amt;
      placeorderdata["ogiDiscount"] =
        this.ogiUsername.length > 0 && this.ogiPassword.length > 0
          ? {
            username: this.ogiUsername.length > 0 ? this.ogiUsername : null,
            password: this.ogiPassword.length > 0 ? this.ogiPassword : null,
            discount: this.ogi_discount_amount
          }
          : null;
      this.total_discount =
        parseFloat(this.coupon_discount_amt) +
        parseFloat(this.ogi_discount_amount);

      // console.log(placeorderdata);
      // =================================email order=========================================
      console.log('payment mode ==> ',this.choosen_paymentmode);
      if (this.choosen_paymentmode === "1") {
        this.service.placeorder(placeorderdata).subscribe((res: any) => {
          if (res["emailOrder"].error <= 0) {
            // alert(res["emailOrder"].msg);

            const alerttext =
              this.manageTexts.order_confirmation_message !== undefined &&
                this.manageTexts.order_confirmation_message !== ""
                ? this.manageTexts.order_confirmation_message
                : "Congratulations, you have successfully placed an order !!!";
            alert(alerttext);
           // this.cookieService.delete("Cart_items", "/");
            localStorage.removeItem('Cart_items');
            jQuery(".cartCount").html("(0)");
            this.ru.navigateByUrl("/home");
          } else {
            const alerttext =
              this.manageTexts.order_cancel_message !== undefined &&
                this.manageTexts.order_cancel_message !== ""
                ? this.manageTexts.order_cancel_message
                : "Your order have not been placed. Please try again later !!!";
            alert(alerttext);
            // alert(this.manageTexts.order_cancel_message);
            // alert(res["emailOrder"].error);
          }
        });
      }else if (this.choosen_paymentmode === "3") {
        
        // ========================== VIVA order ===========================
        this.cookieService.set(
          "paypal_order",
          JSON.stringify(placeorderdata),
          null,
          "/"
        );
        this.service
          .saveOrderdataBeforePlaceorder(placeorderdata)
          .subscribe((res: any) => {
            console.log(res["orderData"]);
            // return false;
            if (res["orderData"].error <= 0) {
              this.orderdataid = res["orderData"].orderdataid;
              this.cookieService.set(
                "orderdataid",
                this.orderdataid,
                null,
                "/"
              );
  
              ////////////////////////////
        placeorderdata['orderDataId']=this.orderdataid;      
        this.service
        .vivapaymentOrder(placeorderdata)
        .subscribe((res: any) => {
          
        if (res['orderData'].error <= 0) {  
          console.log('viva',res);
          window.location.href = 'https://www.vivapayments.com/web/newtransaction.aspx?ref='+res['orderData'].VivaorderId;
          }else{
            const alerttext =
              this.manageTexts.order_cancel_message !== undefined &&
                this.manageTexts.order_cancel_message !== ""
                ? this.manageTexts.order_cancel_message
                : "Your order can not be placed. Please try again later !!!";
            alert(alerttext);
          }
        })
      ////////////////////////

            } else {
              const alerttext =
                this.manageTexts.order_cancel_message !== undefined &&
                  this.manageTexts.order_cancel_message !== ""
                  ? this.manageTexts.order_cancel_message
                  : "Your order can not be placed. Please try again later !!!";
              alert(alerttext);
            }
          })   
        
          
      } else {
        // ========================== Paypal order ===========================
        this.cookieService.set(
          "paypal_order",
          JSON.stringify(placeorderdata),
          null,
          "/"
        );
        this.service
          .saveOrderdataBeforePlaceorder(placeorderdata)
          .subscribe((res: any) => {
            console.log(res["orderData"]);
            // return false;
            if (res["orderData"].error <= 0) {
              this.orderdataid = res["orderData"].orderdataid;
              this.cookieService.set(
                "orderdataid",
                this.orderdataid,
                null,
                "/"
              );
              setTimeout(function () {
                jQuery(".formPaypal").submit();
              }, 2000);
            } else {
              const alerttext =
                this.manageTexts.order_cancel_message !== undefined &&
                  this.manageTexts.order_cancel_message !== ""
                  ? this.manageTexts.order_cancel_message
                  : "Your order can not be placed. Please try again later !!!";
              alert(alerttext);
            }
          });
      }
    }
  }
  deletecartitems() {
   // this.cookieService.delete("Cart_items");
    localStorage.removeItem('Cart_items');
    // console.log('paypal_order', JSON.parse(this.cookieService.get("paypal_order")) );
    // console.log('Cart_items', JSON.parse(this.cookieService.get("Cart_items")) );
    jQuery(".cartCount").html("(0)");
    return true;
  }
  generateArray(obj) {
    return Object.keys(obj).map(key => {
      return obj[key];
    });
  }
  getCitylist(stateid) {
    this.service.getCitylist(stateid).subscribe((cityInfo: Object) => {
      this.statedata = cityInfo["citylist"];
      this.checkoutconfig["shipped_address"].map((checkoutcon, key) => {
        if (checkoutcon.field === "city") {
          checkoutcon.citylist = cityInfo["citylist"];
        }
      });
    });
  }
  public validateCouponCode(
    couponcode: string = "",
    isupdate: boolean = false
  ) {
    const grandTotal = this.shippingdata["__zone_symbol__value"].shippinginfo
      .total_with_shipping;
    this.grandTotalInnitial = grandTotal;
    const cookieKey = "Cart_items";
    // const existingcartprod = this.cookieService.check(cookieKey)
    //   ? JSON.parse(this.cookieService.get(cookieKey))
    //   : [];
      const existingcartprod =   (localStorage.getItem(cookieKey) != null) 
      ? JSON.parse(localStorage.getItem(cookieKey))
      : []; 
    const couponsToApplyOn = [];

    existingcartprod.filter((productDat, productDataKey) => {
      couponsToApplyOn[productDataKey] = {
        id: productDat.productid,
        total_price: productDat.total_price
      };
    });
    const cookieCouponKey = "applied_coupon";
    const appliedcoupon = this.cookieService.check(cookieCouponKey)
      ? JSON.parse(this.cookieService.get(cookieCouponKey))
      : [];

    // console.log('grandTotal' , grandTotal);
    // console.log('total_with_shipping' , this.shippingdata['__zone_symbol__value'].shippinginfo.total_with_shipping);
    // console.log('this.checkoutprice' , this.checkoutprice);

    if (appliedcoupon.length > 0 && isupdate === false) {
      this.is_valid_coupon = false;
      this.coupon_apply_msg = "Only one coupon can be applied !";
      return false;
    } else {
      this.service
        .applyCouponCode(
          couponcode,
          grandTotal,
          couponsToApplyOn,
          this.checkoutprice
        )
        .subscribe((res: Object) => {
          const returnCouponData = res;

          if (returnCouponData["appliedCouponData"].error === 0) {
            this.is_valid_coupon = true;
            this.appliedCoupon = this.couponcodeInnitial = couponcode;
            this.shippingdata[
              "__zone_symbol__value"
            ].shippinginfo.total_with_shipping =
              returnCouponData["appliedCouponData"].grand_total;
            this.cookieService.set(
              cookieCouponKey,
              JSON.stringify(couponcode),
              null,
              "/"
            );
            this.coupon_apply_msg = returnCouponData["appliedCouponData"].msg;
            this.coupon_discount_amt =
              returnCouponData["appliedCouponData"].applied_discount_amount;
            this.disableFirstCoupon = true;

            if (isupdate) {
              if (this.ogiUsername.length > 0 && this.ogiPassword.length > 0) {
                this.validateOgiUser(
                  this.ogiUsername,
                  this.ogiPassword,
                  returnCouponData["appliedCouponData"].grand_total
                );
              }
            }
          } else {
            this.is_valid_coupon = false;
            this.coupon_apply_msg = returnCouponData["appliedCouponData"].msg;
          }
        });
    }
    const dataAr = {
      returnval: "yes"
    };
    return dataAr.returnval;
  }

  // =========================== coupon code 2 ======================================
  public validateCouponCode2(
    couponcode: string = "",
    isupdate: boolean = false
  ) {
    let couponcode2 = couponcode;
    // console.log("this.couponcodeInnitial", this.couponcodeInnitial);
    // console.log("couponcode2", couponcode2);
    if (couponcode === "") {
      this.coupon_apply_msg2 = "Invalid coupon code";
      return false;
    }
    if (this.couponcodeInnitial === couponcode2) {
      this.coupon_apply_msg2 = "Duplicate coupon code";
      return false;
    }
    const grandTotal = this.shippingdata["__zone_symbol__value"].shippinginfo
      .total_with_shipping;
    const cookieKey = "Cart_items";
    // const existingcartprod = this.cookieService.check(cookieKey)
    //   ? JSON.parse(this.cookieService.get(cookieKey))
    //   : [];
      const existingcartprod =   (localStorage.getItem(cookieKey) != null) 
      ? JSON.parse(localStorage.getItem(cookieKey))
      : []; 
    const couponsToApplyOn = [];

    existingcartprod.filter((productDat, productDataKey) => {
      couponsToApplyOn[productDataKey] = {
        id: productDat.productid,
        total_price: productDat.total_price
      };
    });
    const cookieCouponKey = "applied_coupon";
    const appliedcoupon = this.cookieService.check(cookieCouponKey)
      ? JSON.parse(this.cookieService.get(cookieCouponKey))
      : [];
    this.service
      .applyCouponCode2(
        this.couponcodeInnitial,
        this.grandTotalInnitial,
        couponsToApplyOn,
        this.checkoutprice,
        couponcode2
      )
      .subscribe((res: Object) => {
        const returnCouponData = res;

        if (returnCouponData["appliedCouponData2"].error === 0) {
          this.is_valid_coupon2 = true;
          this.appliedCoupon =
            returnCouponData["appliedCouponData2"]["appled_couponcode"];

          this.shippingdata[
            "__zone_symbol__value"
          ].shippinginfo.total_with_shipping =
            returnCouponData["appliedCouponData2"].grand_total;
          this.cookieService.set(
            cookieCouponKey,
            JSON.stringify(couponcode2),
            null,
            "/"
          );
          this.coupon_apply_msg = returnCouponData["appliedCouponData2"].msg;
          this.coupon_discount_amt =
            returnCouponData["appliedCouponData2"].applied_discount_amount;

          if (isupdate) {
            if (this.ogiUsername.length > 0 && this.ogiPassword.length > 0) {
              this.validateOgiUser(
                this.ogiUsername,
                this.ogiPassword,
                returnCouponData["appliedCouponData2"].grand_total
              );
            }
          }
        } else {
          this.is_valid_coupon2 = false;
          this.coupon_apply_msg2 = returnCouponData["appliedCouponData2"].msg;
        }
      });
    const dataAr = {
      returnval: "yes"
    };
    return dataAr.returnval;
  }

  // =========================== ogi user discount ======================================
  validateOgiUser(
    ogiUsername: any = "",
    ogiPassword: any = "",
    final_grandtotal: any = ""
  ) {
    //  console.log(this.shippingdata['__zone_symbol__value'].shippinginfo.total_with_shipping);
    const grandTotal = this.shippingdata["__zone_symbol__value"].shippinginfo
      .total_with_shipping;

    this.service
      .validateApplyOgiDiscount(
        ogiUsername,
        ogiPassword,
        this.checkoutprice,
        grandTotal
      )
      .subscribe((res: Object) => {
        const returnOgiDiscountData = res;
        this.ogi_discount_false_msg = "";
        if (returnOgiDiscountData["ogiDiscount"].error === 0) {
          this.ogi_discount_applied = true;
          this.ogi_discount_message = returnOgiDiscountData["ogiDiscount"].msg;
          this.shippingdata[
            "__zone_symbol__value"
          ].shippinginfo.total_with_shipping =
            returnOgiDiscountData["ogiDiscount"].grand_total;
          this.ogi_discount_amount =
            returnOgiDiscountData["ogiDiscount"].applied_discount_amount;
          this.ogiUsername = ogiUsername;
          this.ogiPassword = ogiPassword;
          this.final_discount_message +=
            returnOgiDiscountData["ogiDiscount"].msg;
        } else {
          this.ogi_discount_applied = false;
          this.ogi_discount_false_msg =
            returnOgiDiscountData["ogiDiscount"].msg;
        }
      });
  }

  clearcoupon() {
    console.log(this.appliedCoupon);
  }

  validateTermsConditionStatus() {
    if (
      this.choosen_paymentmode !== undefined &&
      this.choosen_paymentmode !== null
    ) {
      if (this.isTermsConditinoChecked === true) {
        this.finalsubmitlock = "show";
      } else {
        this.finalsubmitlock = "hide";
      }
    }
  }
}
