import { PageblocksService } from '../pageblocks.service';
import {Component, OnInit, Input} from '@angular/core';
import { CookieService } from "ngx-cookie-service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { DomSanitizer} from '@angular/platform-browser';
import { environment } from "../../environments/environment";

declare var jQuery: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() jsondata: any;
  @Input() default_lang: any;
  @Input() manageTexts: any;


  public FooterData: any;
  public FooterDesign: any;
  public cartItemCount: any = 0;
  public username: string;
  public hostname = window.location.host;
  public footer_first_colspan: any = 12;
  public footer_second_colspan: any = 0;
  public footer_third_colspan: any = 0;

  constructor(private cookieService: CookieService,
    public activatedRoute: ActivatedRoute,
    public ru: Router,
    public sanitizer: DomSanitizer,
    public service: PageblocksService
  ) {
    const cookieKey = "Cart_items";
    const existingcartprod = this.cookieService.check(cookieKey) ? JSON.parse(this.cookieService.get(cookieKey)) : [];
    // console.log('cartItemCnt', existingcartprod.length);
    this.cartItemCount = existingcartprod.length;

    // const routerPar = this.activatedRoute.params.subscribe((params: Params) => {
    //   this.username = (params['username'] === '' || params['username'] === undefined) ? 'mycompany' : params['username'];
    //   });
  }

  ngOnInit() {
   //  this.username =  (environment.production === false) ? "mycompany" : ((window.location.host.split(".")[0] === "") ? "mycompoany"
   // : window.location.host.split(".")[0]);
    if (environment.production === false) {
      this.username = 'mycompany';
    } else {
      if (window.location.host.split(".")[0] === "") {
        this.username = 'mycompoany';
      } else {
        this.service.getusernamebyurl().subscribe((hostdata: Object) => {
          this.username = hostdata['hostDetails'];
        });
      }
    }
    this.FooterData = this.getFooterData();
    this.FooterDesign = this.combinedFooterStyle(this.FooterData.design.css_style['.footer .footer_bg'],
    this.FooterData.design.css_style['.footer .footer_font_footer']);


    if(this.FooterData.fields.footer_menu_show ==='1' && this.FooterData.fields.footer_social_show === '1'){
      this.footer_first_colspan = this.footer_second_colspan = this.footer_third_colspan = 4;
    } else if (this.FooterData.fields.footer_menu_show ==='1' || this.FooterData.fields.footer_social_show === '1') {
      this.footer_first_colspan = 8;
      this.footer_second_colspan = this.footer_third_colspan = 4;
    } 
  }

  private combinedFooterStyle(styleguide1, styleguide2) {
    return Object.assign(styleguide1, styleguide2);
  }
  private getFooterData() {
    return this.jsondata.filter(item => {
      return item.block.blockname === "footer";
    })[0];
  }

  gotToTop() {
    jQuery('html,body').animate({scrollTop: 0}, 'slow');
    return false;
  }
  private openPageLink(link) {
    if (link !== "false") {
      window.open(link, "_top");
    }
  }
}
