import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-headerupper',
  templateUrl: './headerupper.component.html',
  styleUrls: ['./headerupper.component.css']
})


export class HeaderupperComponent implements OnInit {
  @Input() aboveMenuData: any;
  @Input() TranslatedText: any;
  @Input() shopconfiguration: any;
  constructor() {}

  ngOnInit() {
  }

}
