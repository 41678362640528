import { Component, OnInit , Output, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { PageblocksService } from "../../pageblocks.service";
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from "ngx-cookie-service";


declare var $: any;
@Component({
  selector: 'app-productsearch',
  templateUrl: './productsearch.component.html',
  styleUrls: ['./productsearch.component.scss']
})
export class ProductsearchComponent implements OnInit {
  loading: boolean = true;
  keyword = 'product_name';
  @Output() searchsuggetion: EventEmitter<any> = new EventEmitter<{ searchby: string }>();
  @Output() orderbythis: EventEmitter<any> = new EventEmitter<{ shortby: string, searchby: string }>();

  public default_lang: String = "";
  public shortby_value: String = 'newest';
  public search: any;
  public searchdata: any;
  public breadcrumbData: any;
  public translatedData: any;
  public homeblocks: any;

  public selectedProductImage: any;
  public selectedProductTitle: any;
  public formProductdetails:  any;
  public productdata: any;
  public variation_id_str: any;
  public variation_str: any;
  public variationStr: string;
  public variationIdStr: string;
  public instantBuyProductid: any;
  public searSuggessionKeys: any;
  public slug: string;
  public username: string;
  varientsSelected: Array<any> = [];
  public searchKeySuggestion: any;
  public search_term: any = '';


  constructor(
    public ru: Router,
    public ActivatedRoute: ActivatedRoute,
    public service: PageblocksService,
    public sanitizer: DomSanitizer,
    private cookieService: CookieService,
    public _homeBLocks: PageblocksService,
    public activatedRoute: ActivatedRoute,) {
    this.default_lang = JSON.parse(this.cookieService.get('defaultlang'));
    const routerPar = this.activatedRoute.params.subscribe((params: Params) => {  
    this.username = (params['username'] === '' || params['username'] === undefined) ? 'mycompany' : params['username'];
    this.slug = (params['slug'] === '' || params['slug'] === undefined) ? 'home' : params['slug'];
    })
  }

  ngOnInit() {
    $('body.body').css('background','none');
    this.service.getSearchDataProducts(this.search,null,null,'newest').subscribe((searchresultdata: Object) => {  
      var searchBackground=searchresultdata['searchResult'].Page.design[".page_background .page_bg"].background;
    if(searchBackground){  
      $('body.body').css('background',searchBackground);
    }else{
      $('body.body').css('background','none');
    }
    })
    this.getsearchresult();
  }
  getsearchresult(orderbythis: string = 'newest') {
    
    this.ActivatedRoute.paramMap.subscribe(params => {
      this.loading = true;
      this.search = params.get("search");
      this.shortby_value = orderbythis;
      this.service.getSearchData(this.search,null,null,orderbythis).subscribe((searchresultdata: Object) => {
        this.searchdata = searchresultdata['searchResult'].Page;
        this.breadcrumbData = searchresultdata['searchResult'].Breadcramb;
        // this.translatedData = searchresultdata['searchResult'].TranslatedText;
        this.translatedData = searchresultdata['searchResult'];
        this.loading = false;
        this.searSuggessionKeys=this.searchdata.product_search_keys;
        console.log('search_key',this.searSuggessionKeys);
      });
    });
  }
  searchproduct(shortby: any, searchby: any = null) {
    console.log(searchby);
    this.loading = true;
    this.shortProductBy(shortby, searchby);
    this.loading = false;
  }
  showFullDesc(e) {
    // $(e.target).next('.shot_desctiption_fulltext').css({ "display": "block" });
    // $(e.target).closest('.bg-box').find('.viewmore').css({"display": "block"});
    $(e.target).closest(".bg-box").find(".overlay_bg,.viewmore").css({ display: "inline-block" });
  }
  hideFullDesc(e) {
    // e.target.style.display = 'none';
    // $(e.target).closest('.bg-box').find('.viewmore').css({"display": "none"});
    $(e.target).closest(".bg-box").find(".overlay_bg,.viewmore").css({ display: "none" });
  }

  openProductAddedModal(productid) {

    this.formProductdetails = {};
    this.service.getProductDetails(productid).subscribe((dataProductDet: Object) => {
      this.productdata = dataProductDet;
     // console.log('product details', this.productdata);
      // ======================== setting modal data ===============================
      this.selectedProductTitle = this.productdata.Configuration.ProductDetails.name;
      this.productdata.Configuration.ProductDetails.media.forEach((mediaData, mediaIndex) => {
        if ( mediaData.is_default ) {
          this.selectedProductImage = mediaData.product_image;
        }
      });
      // ======================= end setting modal data ===========================

      // ======================= creating the cart data ===========================
      this.formProductdetails["productcode"] = this.productdata.Configuration.ProductDetails.main_product_code;
      this.formProductdetails["stock_left"] = this.productdata.Configuration.ProductDetails.main_product_stock;

      this.formProductdetails["productid"] = this.productdata.Configuration.ProductDetails.id;
      this.formProductdetails["size"] = this.productdata.Configuration.ProductDetails.size;
      this.formProductdetails['currency'] = this.productdata.Configuration.ProductDetails.currency_code;
      this.formProductdetails["weight"] = this.productdata.Configuration.ProductDetails.weight;
      this.formProductdetails['image'] = this.selectedProductImage;
      this.formProductdetails['main_product_stock'] = this.productdata.Configuration.ProductDetails.main_product_stock;
      this.formProductdetails['quantity'] = 1;
      this.formProductdetails['total_price'] = parseFloat(this.productdata.Configuration.ProductDetails.discountPrice);
      this.formProductdetails['SelectedVarient'] = [];
      // ================SelectedVarient if any===================================
      if ( this.productdata.Configuration.ProductDetails.variations !== undefined &&
        this.productdata.Configuration.ProductDetails.variations.length > 0) {
          this.varientsSelected = [];
          this.variationStr = this.variationIdStr =  "";
          this.productdata.Configuration.ProductDetails.variations.forEach((variationData, variationKey) => {
            variationData.values.forEach((variationDataDetails, indexvalue) => {
              if (indexvalue > 0) {
                return true;
              }
              variationDataDetails["VarientType"] = variationData.name;

              // ================== variation selected id string and choice string =======
              if (this.variationStr === "") {
                this.variationStr = variationDataDetails.choice;
                this.variationIdStr = variationDataDetails.id;
              } else {
                this.variationStr = this.variationStr + "," + variationDataDetails.choice;
                this.variationIdStr = this.variationIdStr + "," + variationDataDetails.id;
              }

              this.variation_str = this.variationStr;
              this.variation_id_str = this.variationIdStr;
              // ================== variation selected id string and choice string =======

              this.varientsSelected.push(variationDataDetails);
            });
          });
          this.formProductdetails['variation_id_str'] = this.variation_id_str ;
          this.formProductdetails['variation_str'] = this.variation_str ;
          this.formProductdetails['SelectedVarient'] = this.varientsSelected;

          const temptotal =  this.formProductdetails['total_price'] +
           ((this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']] !== undefined)
           ? parseFloat( this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']].additional_price)
           : 0);

          this.formProductdetails['total_price'] = parseFloat(temptotal).toFixed(2);
          // variation combination productcode and stock_left
          this.formProductdetails['productcode'] =
          (this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']] !== undefined)
          ?  this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']].product_code : null;
          this.formProductdetails['stock_left'] =
          (this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']] === undefined) ?
          0 :
          this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']].stock_left;

      }
      // ================end SelectedVarient if any===================================


      // ============== check and setting up the cookie ====================
      const cookieKey = "Cart_items";
      const existingcartprod = this.cookieService.check(cookieKey)
        ? JSON.parse(this.cookieService.get(cookieKey))
        : [];
      const products = existingcartprod.filter((product, key) => {
        if ( product.variation_id_str + "-" + product.productid ===
          this.variation_id_str + "-" + this.formProductdetails["productid"]
        ) {
          return false;
        }
        return true;
      });

      products.push(this.formProductdetails);
      this.cookieService.set(cookieKey, JSON.stringify(products), 24 * 100, "/");
      $(".cartCount").html("(" + products.length + ")");
    //  console.log('after set cookie', JSON.parse(this.cookieService.get(cookieKey)));
      // =============== end check and setting up the cookie ================


     
      // =====================end creating the cart data ==========================

      // ======= open modal ===============
      $('#instantBuy').modal({
        show: 'true'
    });
    // ========= end open modal ===========
    });
  }

  getSearchKeys(searchKey:any){
    
    alert('call api to get product names');
    console.log(searchKey);

    this._homeBLocks.getSearchSuggionData(this.username, this.slug, searchKey)
    .subscribe((searchKeyData: Object) => {
      this.searSuggessionKeys = searchKeyData;
      console.log(searchKeyData);
      // if (this.homedataDetail.Configuration.Page.category_products === undefined &&
      //   this.homedataDetail.Configuration.Page.block.length <= 0) {
     
      // }
    });

  }


  //////////////////////////////////////
  shortProductBy(shortby: any, searchby: any = null) {
    this.loading = true;
    this.shortby_value = '';
    this.shortby_value = shortby;

    if (this.searSuggessionKeys != undefined) {
      this.searSuggessionKeys.forEach((suggessionData, suggessonIndex) => {
        if (suggessionData.product_name == searchby) {
          searchby = suggessionData.product_name_default;
        }
      });
    }
    if (searchby != null || searchby != '') {
      this.orderbythis.emit({ shortby, searchby });
    } else {
      this.orderbythis.emit({ shortby });
    }


    this.loading = false;
  }
    //  new search with autopopulate 
    selectEvent(item) {
      let searchbynew = item.product_name;
      if (this.searSuggessionKeys != undefined) {
        this.searSuggessionKeys.forEach((suggessionData, suggessonIndex) => {
          if (suggessionData.product_name == searchbynew) {
            searchbynew = suggessionData.product_name_default;
          }
        });
      }
  
      this.ru.navigate(['productsearch/'+ searchbynew]);
     }
    onChangeSearch(searchby, shortby_value) {
      // fetch remote data from here
      // And reassign the 'data' which is binded to 'data' property.
  
      this.search_term = searchby;
      //this.loading = true;
  
      //this.shortProductBy(shortby_value, searchby);
      //this.loading = false;
      // console.log('now ' + searchby);
      // console.log('shortby_value ' + shortby_value);
      // this.searchsuggetion.emit({searchby});
      //this.orderbythis.emit({item,shortby_value});
      console.log('onChangeSearch');
  
    }
  
    onFocused(e) {
      // do something
      console.log('onFocused');
    }


  ///////////////////////////////////////

}
