import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: 'app-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss']
})
export class SearchboxComponent implements OnInit {
  keyword = 'product_name';
  @Input() content: any;
  @Input() searSuggessionKeys: any;
  public search_term: any = '';
  public selectedProductTitle: any;
  @Output() searchFireEvent = new EventEmitter<any>();
  placeholder: string;

  constructor(public ru: Router) { }

  ngOnInit() {
    console.log(this.searSuggessionKeys);
    console.log(this.content);
    this.placeholder=this.content.fields.search_button_text ===''?'Search':this.content.fields.search_button_text;
  }
  // onSubmitSearch(value: string) {
  //   this.ru.navigate(['searchresults/'+value]);
  // }

  searchproduct(searchby){
    if(searchby == ''){
      return false;
    }
    this.search_term = searchby;
    if (this.searSuggessionKeys != undefined) {
      this.searSuggessionKeys.forEach((suggessionData, suggessonIndex) => {
        if (suggessionData.product_name == searchby) {
          searchby = suggessionData.product_name_default;
        }
      });
    }
    this.searchFireEvent.emit(true);
    this.ru.navigate(['productsearch/'+searchby]);
  }
   selectEvent(item) {
    let searchbynew = item.product_name;
    if (this.searSuggessionKeys != undefined) {
      this.searSuggessionKeys.forEach((suggessionData, suggessonIndex) => {
        if (suggessionData.product_name == searchbynew) {
          searchbynew = suggessionData.product_name_default;
        }
      });
    }
    this.searchFireEvent.emit(true);
    this.ru.navigate(['productsearch/'+ searchbynew]);
   }
   onChangeSearch(searchby) {
     this.search_term = searchby;
   } 
   onFocused(e) {
     // do something
   }

}
