import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { FormBuilder, FormGroup, Validator } from "@angular/forms";
import { PageblocksService } from "../../pageblocks.service";
import { CookieService } from "ngx-cookie-service";


declare var $: any;

@Component({
  selector: "app-contactus",
  templateUrl: "./contactus.component.html",
  styleUrls: ["./contactus.component.scss"]
})
export class ContactusComponent implements OnInit {
  @Input() content: any;
  @Input() form: FormGroup;
  @Input() contactusReturn: any;
  public formdata: any;
  public language: string;
  public contact_loading: boolean;
  public errormessage = '';
  public successmessage = '';
  public default_lang = "";


  constructor(
    public sanitizer: DomSanitizer,
    private PageblocksService: PageblocksService,
    private cookieService: CookieService
  ) {
    this.default_lang = JSON.parse(this.cookieService.get('defaultlang'));
  }

  ngOnInit() {
    console.log(this.content);
  }
  openPageLink(link) {
    if (link !== "false") {
      window.open(link, "_top");
    }
  }
  onContactSubmit(formContactdetails) {
    this.errormessage = '';
    this.contact_loading = true;
    this.formdata = formContactdetails.value;
    //  Object.keys(this.content.fields.contactfield).forEach( (keyvalue, contentData) => {
    //       this.content.fields.contactfield[keyvalue].field_name =  this.content.fields.contactfield[keyvalue].actual_field_name;
    // });
        this.formdata.mailto = this.content.fields.contactus_email;
     //  this.formdata.mailto = 'dts.abhijith@dreamztech.com';
       this.formdata.contactfield = this.content.fields.contactfield;

       this.PageblocksService.sendEmail(this.formdata).subscribe((res: Object) => {
       this.contact_loading = false;
       this.contactusReturn = res;
       this.successmessage = '';
       if (this.contactusReturn.ContactEmail.error) {
         // $('#' + this.contactusReturn.ContactEmail.idfield).get(0).scrollIntoView();
         // $('#' + this.contactusReturn.ContactEmail.idfield).focus();

          Object.keys(this.content.fields.contactfield).forEach( (keyvalue, contentData) => {
            // this.content.fields.contactfield[keyvalue].field_name =  this.content.fields.contactfield[keyvalue].actual_field_name;
            this.content.fields.contactfield[keyvalue].errormessage = '';
            this.content.fields.contactfield[keyvalue].error = 0;
            console.log(this.content.fields.contactfield[keyvalue].field_name);
            // console.log(this.contactusReturn.ContactEmail.field);
            // if (this.content.fields.contactfield[keyvalue].field_name.replace(' ', '_') === this.contactusReturn.ContactEmail.field) {
              if ('field' + keyvalue === this.contactusReturn.ContactEmail.idfield) {
              this.errormessage = this.contactusReturn.ContactEmail.msg;
              this.content.fields.contactfield[keyvalue].error = 1;
              console.log(this.content.fields.contactfield[keyvalue]);
            }
      });
          // this.errormessage = this.contactusReturn.ContactEmail.msg;
       }else {
       // if (!this.contactusReturn.ContactEmail.error) {
        this.successmessage = 'Your contact email is successfully send !!';
          formContactdetails.reset();
       // }
          }
      });
  }
  removeMessage() {
    this.errormessage = '';
  }
}
