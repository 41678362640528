import { Http } from '@angular/http';
import { Component, OnInit, Input, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
declare var jQuery: any;

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {
  @Input() content: any;
  public slideIndex: any = 1;
  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {

  }
  ngAfterViewInit() {
    this.slideIndex = 0;
    this.plusSlides(this.slideIndex);
  }
  plusSlides(n, direction = '',blockid = '') {
    var block_class='slideshow-container';
    if(blockid !=''){
      block_class = block_class+'-'+blockid;
    }
    jQuery('.'+block_class).each(function () {
      var i;
      var slideIndex;

      var slidesContainer = (this);
      var slides = jQuery(this).find('.mySlides');
      // var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      if (direction == 'next') {
          this.slideIndex = (this.slideIndex +  1);
          if (this.slideIndex >= slides.length) {
            this.slideIndex = 0;
          }
      } else if (direction == 'prev') {
        this.slideIndex = (this.slideIndex - 1);
        if (this.slideIndex < 0) {
          this.slideIndex = (slides.length - 1);
        }
      } else {
        this.slideIndex = 0;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }      
      slides[this.slideIndex].style.display = "block";
      dots[this.slideIndex].className += " active";
    });

  }
  // currentSlide(n) {
  //   this.showSlides(slideIndex = n);
  // }
  

}
