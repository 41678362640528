import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { HomeComponent } from "./home/home.component";
import { HttpClientModule } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { MenusComponent } from "./header/menus/menus.component";
import { HeaderupperComponent } from "./header/headerupper/headerupper.component";
import { HeaderuppersliderComponent } from "./customcomps/headerupperslider/headerupperslider.component";
import { MaindescriptonComponent } from "./customcomps/maindescripton/maindescripton.component";
import { VerticalcolumnComponent } from "./customcomps/verticalcolumn/verticalcolumn.component";
import { HorizontalrowComponent } from "./customcomps/horizontalrow/horizontalrow.component";
import { ContactusComponent } from "./customcomps/contactus/contactus.component";
import { ShowcaseComponent } from "./customcomps/showcase/showcase.component";
import { ProductdetailsComponent } from "./productdetails/productdetails.component";
import { ProductcategoryComponent } from "./productcategory/productcategory.component";
import { BlocktitleComponent } from "./customcomps/blocktitle/blocktitle.component";
import { CartComponent } from "./cart/cart.component";
import { CookieService } from "ngx-cookie-service";
import { CheckoutComponent } from "./checkout/checkout.component";
import { ShowErrorsComponent } from "./app.show-errors.component";
import { NotfoundComponent } from "./notfound/notfound.component";
import { OrderComponent } from "./order/order.component";
import { ChatComponent } from "./chat/chat.component";
// import { LazyLoadImageModule } from "ng-lazyload-image";
// import {LazyLoadImageModule} from "ng-lazyload-image";
import { InstantbuyComponent } from "./customcomps/instantbuy/instantbuy.component";


import { TestitComponent } from './testit/testit.component';
import { GalleryComponent } from './customcomps/gallery/gallery.component';
import { GallerymodalComponent } from './customcomps/gallerymodal/gallerymodal.component';
import { SpaceComponent } from './customcomps/space/space.component';
// import { ShareButtonsModule } from '@ngx-share/buttons';
// import { ShareModule } from '@ngx-share/core';
// import {ShareButtonsModule} from "ngx-sharebuttons";
import { TestimonialComponent } from './customcomps/testimonial/testimonial.component';
import { SearchboxComponent } from './customcomps/searchbox/searchbox.component';
import { ProductsearchComponent } from './customcomps/productsearch/productsearch.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { LightboxModule } from 'ngx-lightbox';
import { SafePipe } from './safe.pipe';
import { ReplacePipe } from './replace.pipe';
import { AccordionComponent } from './customcomps/accordion/accordion.component';
import { FullpagebackgroundComponent } from './customcomps/fullpagebackground/fullpagebackground.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    MenusComponent,
    HeaderupperComponent,
    HeaderuppersliderComponent,
    MaindescriptonComponent,
    VerticalcolumnComponent,
    HorizontalrowComponent,
    ContactusComponent,
    ShowcaseComponent,
    ProductdetailsComponent,
    ProductcategoryComponent,
    BlocktitleComponent,
    CartComponent,
    CheckoutComponent,
    ShowErrorsComponent,
    NotfoundComponent,
    OrderComponent,
    ChatComponent,
    InstantbuyComponent,
    TestitComponent,
    GalleryComponent,
    GallerymodalComponent,
    SpaceComponent,
    TestimonialComponent,
    SearchboxComponent,
    ProductsearchComponent,
    SafePipe,
    ReplacePipe,
    AccordionComponent,
    FullpagebackgroundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    // LazyLoadImageModule,
    // ShareButtonsModule // (Required) For share counts
    // ShareButtonsModule.forRoot(),
    AutocompleteLibModule,
    LightboxModule,
    NgxImageZoomModule,
    NgxPaginationModule
  ],
  providers: [
    CookieService,
    // The Favicons is an abstract class that represents the dependency-injection
    // token and the API contract. THe BrowserFavicon is the browser-oriented
    // implementation of the service.
  
    // The BROWSER_FAVICONS_CONFIG sets up the favicon definitions for the browser-
    // based implementation. This way, the rest of the application only needs to know
    // the identifiers (ie, "happy", "default") - it doesn't need to know the paths
    // or the types. This allows the favicons to be modified independently without
    // coupling too tightly to the rest of the code.
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
