import { PageblocksService } from "../pageblocks.service";
// import { FormGroup, FormControl, FormArray, Validators, NgForm } from '@angular/forms';
import { Component, OnInit, Input } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { AsyncPipe } from "@angular/common";
import { Http } from "@angular/http";
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  @Input() slug: string;
  public loading: boolean;

  constructor(
    public ru: Router,
    public activatedRoute: ActivatedRoute,
    public service: PageblocksService,
    private cookieService: CookieService,
    private _http: Http
  )  {
    this.loading = true;
    const routerPar = this.activatedRoute.params.subscribe((params: Params) => {
        this.slug = (params['slug'] === '' || params['slug'] === undefined) ? null : params['slug'];
      });
   }

  ngOnInit() {
    this.loading = false;
  }

}
