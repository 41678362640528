import { Component, OnInit, Input } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { FormGroup, NgForm ,  FormBuilder,  Validators } from "@angular/forms";
import { PageblocksService } from "../pageblocks.service";

declare var jQuery: any;
@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"]
})
export class ChatComponent implements OnInit {

  contactusReturn: any;
  rForm: FormGroup;
  post: any;                     // A property for our submitted form
  name: String = '';
  titleAlert: String = 'This field is required';
  emailAlert: String = 'Proper email required';
  messageAlert: String = 'This field is required';
  phonenoAlert: String = 'This field is required';


  @Input() chatsettings: any;
  public showchatboxstatus: Boolean = false;
  public chatOpened: Boolean = false;
  public ownerOnline: Boolean = false;
  public showError: Boolean = false;

  constructor(
    private cookieService: CookieService,
    public activatedRoute: ActivatedRoute,
    public ru: Router,
    public sanitizer: DomSanitizer,
    public fb: FormBuilder,
    private PageblocksService: PageblocksService
  ) {}

  ngOnInit() {
    this.rForm = this.fb.group({
      'name' : [null, (this.chatsettings.inactive_name_status === '1') ? Validators.required : []],
      'email' : [null, (this.chatsettings.inactive_email_status === '1') ? Validators.email : []],
      'phoneno' : [null, (this.chatsettings.inactive_phone_status === '1') ? Validators.required : []],
      'message' : [null, (this.chatsettings.inactive_message_status === '1') ? Validators.required : []],
    });

     console.log("chatblock", this.chatsettings);
    this.ownerOnline = this.chatsettings.ownerOnline;
    if (this.chatsettings.status === "1") {
      // live chat
      if (this.chatsettings.livechat_appear_after > 0) {
        setTimeout(
          function() {
            this.showchatboxstatus = true;
            if (this.chatsettings.enable_first_message) {
              setTimeout(
                function() {
                  // insert in puher chanel and show in chatbox
                  jQuery("ul.chatwindowul").append(
                    '<li _ngcontent-c3 class="owner_chat">' +
                      this.chatsettings.first_message +
                      "</li>"
                  );
                }.bind(this),
                this.chatsettings.appear_after
              );
            }
          }.bind(this),
          this.chatsettings.livechat_appear_after
        );
      } else {
        this.showchatboxstatus = true;
      }
    } else if ((this.chatsettings.status = 2)) {
      this.showchatboxstatus = true;
    }
  }
  chnageChatOpenedStatus() {
    this.chatOpened = this.chatOpened === true ? false : true;
  }
  sendContacOfflinetMail(formdata: NgForm) {
    console.log(this.chatsettings);
    // formdata.value['emailsendto'] = this.chatsettings.contact_to_email;
    formdata.value['emailsendto'] = 'dts.abhijith@dreamztech.com';
    this.PageblocksService.sendContacOfflinetMail(formdata.value).subscribe((res: Object) => {
      this.contactusReturn = res;
      if (this.contactusReturn.OfflineContact.error) {
        alert('There is some problem. Please try again later !!');
        return false;
      } else {
        alert('Your message is successfully send !!!');
        formdata.reset();
      }
    });
    return false;
  }
}
