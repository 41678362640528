import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { CookieService } from "ngx-cookie-service";
declare var $: any;

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.scss"]
})
export class GalleryComponent implements OnInit {
  @Input() content: any;
  @Input() sliderId: any;
  @Input() galleryid: any;

  public default_lang = "";
  public selectedGalleryTitle = "";
  public selectedGalleryImage = "";
  public currentModalImgCounter = "0";

  constructor(
    public router: Router,
    public sanitizer: DomSanitizer,
    private cookieService: CookieService
  ) {
    this.default_lang = (this.cookieService.get("defaultlang").length > 0) ? JSON.parse(this.cookieService.get("defaultlang")) : '';
  }

  ngOnInit() { }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    //   // Instantiate the Bootstrap carousel
    $(".multi-item-carousel").carousel({
      interval: false
    });
    $(".multi-item-carousel .item").each(function () {
      let next = $(this).next();
      if (!next.length) {
        next = $(this).siblings(":first");
      }
      // next
      //   .children(":first-child")
      //   .clone()
      //   .appendTo($(this));

      // for (let i = 0; i < 2 ; i++) {
      //   next = next.next();
      //   if (!next.length) {
      //     next = $(this).siblings(":first");
      //   }
      //   next
      //     .children(":first-child")
      //     .clone()
      //     .appendTo($(this));
      // }
    });
    $(".multi-item-carousel .glyphicon-chevron-right").off('click').on('click', function () {
      console.log('asdf');
      const thisobj = $(this);
      const fisrtImg = thisobj.closest(".multi-item-carousel").find(".gal_listitem:first");
      const appendToObject = thisobj.closest(".multi-item-carousel").find(".gal_list");
      const secondItem = thisobj.closest(".multi-item-carousel").find(".gal_listitem").eq(1);

      // secondItem.delay(200).hide('slow');
      // fisrtImg.appendTo(appendToObject).hide().show('slow');
      //secondItem.delay(200).hide('slow');
      fisrtImg.delay(200).hide('slow',function(){
        fisrtImg.appendTo(appendToObject).hide().show('slow');

      });
    });

    $(".multi-item-carousel .glyphicon-chevron-left").off('click').on('click', function () {
      console.log('now');
      const thisobj = $(this);
      const lastImg = thisobj.closest(".multi-item-carousel").find(".gal_listitem:last");
      const appendToObject = thisobj.closest(".multi-item-carousel").find(".gal_list");
      // const secondItem = thisobj.closest(".multi-item-carousel").find(".gal_listitem").eq(1);
      // lastImg.hide();
      // thisobj.closest(".multi-item-carousel").find(".gal_list").prepend(lastImg);
      // lastImg.delay(200).show('slow');
          lastImg.hide('slow',function(){
        lastImg.prependTo(appendToObject).hide().show('slow');
      });
    });
  }
  

  one() {
    $(".column").each(function () {
      const thisobj = $(this);
      //  thisobj.style.msFlex = "100%"; // IE10
      // thisobj.style.flex = "100%";
        thisobj.css('msFlex','100%');
        thisobj.css('flex','100%');
  });
  }
  // Two images side by side
  two() {
    $(".column").each(function () {
      const thisobj = $(this);
      // thisobj.style.msFlex = "50%"; // IE10
      // thisobj.style.flex = "50%";
      thisobj.css('msFlex','50%');
      thisobj.css('flex','50%');
  });
  }
  // Four images side by side
  four() {
      $(".column").each(function () {
        const thisobj = $(this);
        // thisobj.style.msFlex = "25%"; // IE10
        // thisobj.style.flex = "25%";
        thisobj.css('msFlex','25%');
        thisobj.css('flex','25%');
    });
  }

  openGalleryImgModeal(galleryData, galleryConter, imgCounter) {
    this.selectedGalleryTitle = galleryData.caption;
    this.selectedGalleryImage = galleryData.media_link;
    this.currentModalImgCounter = imgCounter;
    console.log("abcd");
    $("." + this.galleryid).modal({
      show: "true"
    });
    $("." + this.galleryid)
      .find(".next , .prev")
      .show();
    if (imgCounter < 1) {
      $("." + this.galleryid)
        .find(".prev")
        .hide();
    } else if (imgCounter === this.content.fields.image.length - 1) {
      $("." + this.galleryid)
        .find(".next")
        .hide();
    }
  }
  openGalleryImgModealSlider(galleryData, galleryConter, imgCounter) {
    this.selectedGalleryTitle = galleryData.caption;
    this.selectedGalleryImage = galleryData.media_link;
    this.currentModalImgCounter = imgCounter;
    console.log("imgCounter" + this.currentModalImgCounter);

    $("." + this.galleryid).modal({
      show: "true"
    });
    $("." + this.galleryid)
      .find(".next , .prev")
      .show();
    if (imgCounter < 1) {
      $("." + this.galleryid)
        .find(".prev")
        .hide();
    } else if (imgCounter === this.content.fields.image.length - 1) {
      $("." + this.galleryid)
        .find(".next")
        .hide();
    }
  }

  // $('.carousel .item').each(function(){
  //   const next = $(this).next();
  //   if (!next.length) {
  //     next = $(this).siblings(':first');
  //   }
  //   next.children(':first-child').clone().appendTo($(this));

  //   for (const i=0; i<2; i++) {
  //     next=next.next();
  //     if (!next.length) {
  //       next = $(this).siblings(':first');
  //     }

  //     next.children(':first-child').clone().appendTo($(this));
  //   }
  // });
}
