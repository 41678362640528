import { Component, OnInit, Input, HostListener  } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { AppComponent } from '../app.component';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  @Input() jsondata: any;
  @Input() default_lang: any;
  @Input() default_lang_country: any;
  @Input() TranslatedText: any;
  @Input() shopconfiguration: any;
  

  public MenuData: any;
  public AboveMenu: any;
  public hasHeaderBanner: any = false;
  public show_lang: any = false;
  public header_top:any = 0;
  public sticky:any = 0;
  toplength: number;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    public appcom:  AppComponent
  ) {}

  @HostListener('window:scroll', ['$event']) getScrollHeight(event) {
    // console.log(window.pageYOffset, event);

    if(this.MenuData.fields.fixed_top_menu == 1){
     if (window.pageYOffset > this.sticky && this.toplength<15) {
      this.header_top.classList.add("sticky");
    } else {
      this.header_top.classList.remove("sticky");
    }
  }
 }

  ngOnInit() {
    console.log(this.shopconfiguration);
    this.MenuData = this.jsondata.filter(item => {
      return item.block.blockname === "menu";
    })[0];
    if (this.jsondata[0].block.blockname !== "menu") {
      this.AboveMenu = this.jsondata[0];
      this.hasHeaderBanner = true;
    }    
    this.toplength=0;
    for (const iterator of this.MenuData.TreeMenu) {
      if( iterator.children && this.toplength < iterator.children.length){
        this.toplength=iterator.children.length;
      }
    }
    console.log('this.MenuData',this.toplength);
  }
  //window.onscroll = function() {this.myFunction()};
  ngAfterViewInit() {    
    this.header_top = document.getElementById("headerComp");
this.sticky = this.header_top.offsetTop;
  }
 
  cssDecorate(data) {
    return data.css_style[".menu .menu_bg"];
  }

  manage_lang() {
    this.show_lang = this.show_lang ? false : true;
  }
  changLang(lang: String, code_country: String) {
    this.cookieService.set('defaultlang', JSON.stringify(lang), 1, '/');
    this.cookieService.set('defaultlangcountry', JSON.stringify(code_country), 1, '/');
    this.appcom.changLang(lang, code_country);
  }
}
