import { PageblocksService } from '../../pageblocks.service';
import { Component, OnInit, Input, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CookieService } from "ngx-cookie-service";
import { environment } from '../../../environments/environment';
import { AppComponent } from '../../app.component';
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})

export class MenusComponent implements OnInit {
  @Input() list: any;
  @Input() cssdesign: any;
  @Input() default_lang: any;
  @Input() default_lang_country: any;
  @Input() has_logo: any;
  @Input() compact_menu: any;
  @Input() menu_data: any;


  public shortby_value: String = 'newest';
  public hovered: any;
  public cartItemCount: any = 0;
  public username: any;
  public mobilenavopen: Boolean = false;
  public hostname = window.location.host;
  public show_lang: any = false;
  public total_cart_product_count: any = 0;
  menuSearchBlock: any;
  search: any;
  searchdata: any;
  initialValue: string;
  searchStyle: any;
  framebackground: { 'background-color': any; };
  textColor: any;
  iconzoom: any={};

  constructor(private cookieService: CookieService, public activatedRoute: ActivatedRoute,
    public ActivatedRoute: ActivatedRoute,
    public service: PageblocksService, public appcom:  AppComponent, private router: Router) {
    this.hovered = [];
    const sub = this.activatedRoute.params.subscribe((params: Params) => {
      if (environment.production === false) {
        this.username = 'mycompany';
      } else {
        if (window.location.host.split(".")[0] === "") {
          this.username = 'mycompoany';
        } else {
          this.service.getusernamebyurl().subscribe((hostdata: Object) => {
            this.username = hostdata['hostDetails'];
          });
        }
      }
      const cookieKey = "Cart_items";
      //const existingcartprod = this.cookieService.check(cookieKey) ? JSON.parse(this.cookieService.get(cookieKey)) : [];
      const existingcartprod = (localStorage.getItem(cookieKey) != null) ? JSON.parse(localStorage.getItem(cookieKey)) : []; 
      existingcartprod.filter((product, key) => {
        this.total_cart_product_count = Number(this.total_cart_product_count) + Number(product.quantity);
      });
      jQuery('.cartCount').val(existingcartprod.length);
      this.cartItemCount = existingcartprod.length;
    });
    router.events.subscribe((val) => {
      // see also 
      jQuery('ul.mainParent li').removeClass('active-dropdown');
      jQuery('ul.mainParent li.dropdown em.drop-arrow').removeClass('minus');
      jQuery('ul.mainParent li.dropdown em.drop-arrow').addClass('plus');
    });

  }
  ngOnInit() {
    if(this.compact_menu ==1){
      this.compact_menu = true;
    }else{
      this.compact_menu = false;
    }
    if (this.list) {
      this.PrepareMenuToggle();
       if(jQuery('header').children().length == 1) {
          jQuery('.sidebarmobile').css({'top': '-46px'});
        //  jQuery('.sidebarmobile').attr('style','top:-46px;position:absolute !important');
       }
    }

    this.getsearchresult()
    // this.activatedRoute.params.subscribe((params: Params) => {
    //   this.username = (params['username'] === '' || params['username'] === undefined) ? 'mycompany' : params['username'];
    //   this.service.getPageBlocks(this.username, 'home', 'newest')
    //   .subscribe((data: any) => {
    //      console.log('data',data);
    //     let searchBlock=data.Configuration.Page.block.filter(function callback(element, index, array) {
    //       return element.block.blockname=='search';
    //     });
    //     this.menuSearchBlock= searchBlock[0]?searchBlock[0]:false;
    //     this.searSuggessionKeys= this.menuSearchBlock? this.menuSearchBlock.product_search_keys:[];
    //   });
    // });
     console.log('aaa',this.menu_data)

    this.getSeacrhConfiguration();


    if(this.menu_data.fields.flag_size=="medium"){
      this.iconzoom={"zoom":1.5}
    }
    else if(this.menu_data.fields.flag_size=="large"){
      this.iconzoom={"zoom":2}
    }



    
  }

  searchFireEvent(){
    // console.log('s');
    $('#searchmodal').modal('hide');
  }

  decoratemenu(data) { 
    return data.css_style[".menu .menu_item"];
  }
  
  openSearchModal() { 
    this.initialValue='';
    $('#searchmodal').modal({
      show: 'true'
    });
    this.addmobileclassinnav();
  }

  decorateSubmenu(data) {
    return data.css_style[".menu .sub_menu"];
  }
  decorateSubmenuHover(data) {
    return data.css_style[".menu .sub_menu:hover"];
  }

  hoverstyle(data) {
    return data.css_style[".menu .menu_item:hover"];
  }

  private PrepareMenuToggle() {
    jQuery(document).ready(function ($) {
      $('.mainParent a[data-toggle="dropdown"]').on('mouseover', function (e) {
        const $el = $(this);
        const $parent = $(this).offsetParent(".dropdown-menu");
        $(this).parent("li").toggleClass('open');
        if (!$parent.parent().hasClass('nav')) {
          $el.next().css({
            "top": $el[0].offsetTop,
            "left": $parent.outerWidth() - 1
          });
        }
        $('.nav li.open').not($(this).parents("li")).removeClass("open");
        return false;
      });
      $(document).on('click', 'em.plus', function (event) {
        event.preventDefault();
        console.log('put minus');
        var thisobj = $(this);
        // jQuery('ul.mainParent li').removeClass('active-dropdown');
        // jQuery('ul.mainParent li.dropdown em.drop-arrow').removeClass('minus');
        // jQuery('ul.mainParent li.dropdown em.drop-arrow').addClass('plus');
        thisobj.closest('li.dropdown').addClass('active-dropdown');
        thisobj.addClass('minus').removeClass('plus');
        thisobj.next('.dropdown-menu').show('slow');
      });
      jQuery(document).on('click', 'em.minus', function (event) {
        event.preventDefault();
        console.log('put plus');
        var thisobj = $(this);
        thisobj.closest('li.dropdown').removeClass('active-dropdown');
        thisobj.addClass('plus').removeClass('minus');
        thisobj.next('.dropdown-menu').hide('slow');
      });
    });
  }
  openPageLink(link) {
    if (link !== "false") {
      this.mobilenavopen = false;
      window.open(link, "_top");
    }
  }
  addmobileclassinnav() { 
    if (window.screen.width <= 812 || this.compact_menu) {
     
      this.mobilenavopen = !this.mobilenavopen;
      if (this.mobilenavopen) {
        jQuery('html,body').css({
          overflowY: 'hidden'
        });
        if(jQuery('.mobileflex').length >0){
          jQuery('.mobileflex').css({'height':'80vh'});
        }else { 
 
        }
      } else {
        jQuery('.mobileflex').css({'height':'unset'});
        jQuery('html,body').css({
          height: '',
          overflowY: ''
        });
      }
    // tslint:disable-next-line:one-line
    }else {
      this.mobilenavopen = false;
      jQuery('html,body').css({
        height: '',
        overflowY: ''
      });
    }
  }
  cssDecoratem(data) {
    return data.css_style[".menu .menu_bg"];
  }
  manage_lang() {
    this.show_lang = this.show_lang ? false : true;
  }
  changLang(lang: String, code_country: String) {
    this.cookieService.set('defaultlang', JSON.stringify(lang), 1, '/');
    this.cookieService.set('defaultlangcountry', JSON.stringify(code_country), 1, '/');
    this.appcom.changLang(lang, code_country);
  }



  keyword = 'product_name';
  // @Input() content: any;
  searSuggessionKeys: any=[];
  public search_term: any = '';
  public selectedProductTitle: any;
  // @Output() searchFireEvent = new EventEmitter<any>();
  placeholder: string;
  
  searchproduct(searchby){

    // console.log(searchby,this.keyword);
    // if(searchby == ''){
    //   return false;
    // }
    // this.search_term = searchby;
    // if (this.searSuggessionKeys != undefined) {
    //   this.searSuggessionKeys.forEach((suggessionData, suggessonIndex) => {
    //     if (suggessionData.product_name == searchby) {
    //       searchby = suggessionData.product_name_default;
    //     }
    //   });
    // }
    this.searchFireEvent();
    this.router.navigate(['productsearch/'+this.search_term]);
  }


  getSeacrhConfiguration(){
    this.service.getCategorySearch().subscribe((dataCategoryDet: Object) => {
      console.log(dataCategoryDet);
      this.placeholder=dataCategoryDet['categorySearch']['text']?dataCategoryDet['categorySearch']['text']:'Search';
      this.searchStyle=dataCategoryDet['categorySearch'];
      this.framebackground={'background-color':dataCategoryDet['categorySearch']['background-color']};
      this.textColor=dataCategoryDet['categorySearch']['css_style']['search_text_color'];
    });
  }

  getsearchresult(orderbythis: string = 'newest') {
    
    this.ActivatedRoute.paramMap.subscribe(params => {
      this.search = params.get("search");
      this.service.getSearchDataProducts(this.search,null,null,orderbythis).subscribe((searchresultdata: Object) => {
        this.searchdata = searchresultdata['searchResult'].Page;
        // this.breadcrumbData = searchresultdata['searchResult'].Breadcramb;
        // this.translatedData = searchresultdata['searchResult'].TranslatedText;
        // this.loading = false;
        this.searSuggessionKeys=(this.searchdata && this.searchdata.product_search_keys)?this.searchdata.product_search_keys:'';
        console.log('search_key',this.searSuggessionKeys);
      });
    });
  }
   selectEvent(item) {
    let searchbynew = item.product_name;
    if (this.searSuggessionKeys != undefined) {
      this.searSuggessionKeys.forEach((suggessionData, suggessonIndex) => {
        if (suggessionData.product_name == searchbynew) {
          searchbynew = suggessionData.product_name_default;
        }
      });
    }
    this.searchFireEvent();
    this.router.navigate(['productsearch/'+ searchbynew]);
   }
   onChangeSearch(searchby) {
      console.log(searchby);
     this.search_term = searchby;
   } 
   onFocused(e) {
     // do something
   }
}
