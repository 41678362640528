import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-blocktitle',
  templateUrl: './blocktitle.component.html',
  styleUrls: ['./blocktitle.component.scss']
})
export class BlocktitleComponent implements OnInit {
  @Input() blocktitle: any;
  constructor() { }

  ngOnInit() {

  }

}
