import { PageblocksService } from "../pageblocks.service";
import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer, Title, Meta } from "@angular/platform-browser";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { EMPTY as empty } from "rxjs";
import { Lightbox } from 'ngx-lightbox';
import { environment } from '../../environments/environment';



declare var jQuery: any;
declare var variation_values: any;

@Component({
  selector: "app-productdetails",
  templateUrl: "./productdetails.component.html",
  styleUrls: ["./productdetails.component.scss"],
  providers: [PageblocksService]
})
export class ProductdetailsComponent implements OnInit {
  varientsSelected: Array<any> = [];

  @Input() productdata: any;
  @Input() homedata: any;
  @Input() loading: boolean;


  public sub: any;
  public productid: any;
  public username: any;
  public language: any;
  // public productslug: any; // commented because it's not in use from app routing module
  public quantity: number;
  public disableQuantity: Boolean = false;
  public main_price: any;
  public main_total_price: any;
  public base_price: any;
  public productvariations: any;
  public temp_total: any;
  public formProductdetails: any;
  public variationStr: string;
  public variationIdStr: string;
  public variation_str: string;
  public variation_id_str: string;
  public disable_add_to_cart: any = false;
  public currency: any = "";
  public stockleft: number;
  public variationData = [];
  public productcode: string;
  public cookenameStr: string;
  public porductCookie: {};
  public match_varation_str: string;
  public product_img_listblock: any = "785px";
  public visit_cart: Boolean = true;
  public manageTexts: any;
  public defaultImage: any;
  public currentPageUrl: string;
  public postDescripton: any;
  public tesimg = "https://www.ourglobalidea.com/rep/files/smeeshop/product/55678/printed-chiffon-dress_01.jpg";
  public tesimgT = "https://wittlock.github.io/ngx-image-zoom/assets/thumb.jpg";
  public _album: Array<any> = [];
  public _albums: Array<any> = [];
  public mainImgArrCount: any = 0;
  public ogiimgpath = environment.ogiimageendpoint;
  public show_busket: any = true;
  public slug: string;
  public productdataDetail: any;
  spike_price: number;
  temp_price: string;
  ismobile: boolean=false;
  blocks: any=[];
  haveDetailsBlock: boolean;
  homedataDetail: any;



  constructor(
    public ru: Router,
    public activatedRoute: ActivatedRoute,
    public product: PageblocksService,
    public sanitizer: DomSanitizer,
    private cookieService: CookieService,
    private meta: Meta,
    public _lightbox: Lightbox,
    private titleService: Title,
  ) {
    this.meta.removeTag("name='og:image'");
    this.currentPageUrl = window.location.href;
    console.log(this.currentPageUrl);
    console.log(this.ogiimgpath);
    //this.postDescripton = this.productdata.Configuration.ProductDetails.full_description|slice: 0:10 ;
    const sub = this.activatedRoute.params.subscribe((params: Params) => {
      this.productid = params["productid"];
      this.username = (params['username'] === '' || params['username'] === undefined) ? 'mycompany' : params['username'];
      this.slug =  (params["slug"] === "" || params["slug"] === undefined) ? null : params["slug"];
      this.language = params["language"] === "" ? "en" : params["language"];
      // this.productslug = params["productslug"]; // commented because it's not in use from app routing module
      this.base_price = 0;
      this.formProductdetails = false;
      this.variationStr = "";
      this.variationIdStr = "";
      this.loading = true;
      this.stockleft = 0;
      this.loadData();
    });
    this.productdata = [];
    this.quantity = 1;
    this.temp_total = 0;
    this.main_price = this.quantity * this.base_price;
    console.log('price',this.main_price);




  }
  ngOnInit() {
    // this.loadData();
    this.loadDataDetail();
    this.loadBlockData();
    console.log(window.innerWidth);
    if(window.innerWidth < 767){
      this.ismobile=true;
    }
  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  loadBlockData(orderdata: string = 'newest',searchby: any = null) {
    this.product.getPageBlocks(this.username, this.productid, orderdata,searchby)
      .subscribe((data: any) => {
        this.homedataDetail = data;
        this.blocks=data.Configuration.Page.block;
        this.checkcategory(this.blocks);
      });
  }

  checkcategory(blocks){
    let flag=true;
    blocks.forEach(element => {
      console.log(element);
      if(element.block.blockname=='product_details' && flag==true){
        flag=false;
      }
    });
    this.haveDetailsBlock=flag;
  }
  loadDataDetail(orderdata: string = 'newest',searchby: any = null) {
    this.product.getPageBlocks(this.username, this.slug, orderdata,searchby)
      .subscribe((data: Object) => {
        this.productdataDetail = data;
        if(this.productdataDetail.Configuration.Page.details == undefined){
          this.product.getPageBlocks(this.username, 'home', orderdata,searchby)
          .subscribe((edata: Object) => {
            this.productdataDetail = edata;
            this.meta.removeTag("name='title'");
            this.meta.addTag({ name: 'title', content: this.productdataDetail.Configuration.Page.details.meta.meta_title });
            this.titleService.setTitle(this.productdataDetail.Configuration.Page.details.meta.meta_title);
          });
        }
        if(this.productdataDetail.Configuration.Page.details !== undefined){
          this.meta.removeTag("name='title'");
          this.meta.addTag({ name: 'title', content: this.productdataDetail.Configuration.Page.details.meta.meta_title });
          this.titleService.setTitle(this.productdataDetail.Configuration.Page.details.meta.meta_title);
        }
        // console.log('meta_title',this.cartdataDetail.Configuration.Page.details.meta);
    });
  }
  loadData() {
    jQuery("#myTabs a").click(function (e) {
      e.preventDefault();
      jQuery(this).tab("show");
    });
    this.loading = true;
    if (this.language == undefined) {
      console.log('this.language' + this.language);

      this.product.getCompanyDefaultLang(this.username, this.language).subscribe((defaultlangGet: Object) => {
        console.log('defaultlangGet' + defaultlangGet);
        this.language = defaultlangGet['DefaultLang'].lang;
        this.cookieService.set("defaultlang", JSON.stringify(this.language), 1, "/");
        this.getProductDetails();
      });
    } else {
      this.getProductDetails();
    }
  }

  getProductDetails() {
    this.product.getProductDetails(this.productid, this.username, this.language)
      .subscribe((dataProductDet: Object) => {
        this.productdata = dataProductDet;
        this.temp_price = Number(this.productdata.Configuration.ProductDetails.price).toFixed(2);
        if (this.productdata.Configuration.ProductDetails.length <= 0) {
          this.ru.navigateByUrl("/404");
          return false;
        }

        this.productdata.Configuration.ProductDetails.media.forEach(
          (item, index) => {
            if (item.filetype == 'image') {
              if (item.is_default == true) {
                this.defaultImage = item.product_image;
                //this.meta.removeTag("name='og:image:url'");
                this.meta.addTag({ name: 'og:image', content: item.product_image.replace('www.', '') });
                //this.meta.addTag({ name: '', content: item.product_image });
                //this.meta.addTag({ name: 'og:image:secure_url', content: item.product_image });                
                this.meta.addTag({ name: 'og:image:width', content: '500' });
                this.meta.addTag({ name: 'og:image:height', content: '200' });

                this.meta.addTag({ name: 'fb:app_id', content: '1104081043121078' });
                this.meta.addTag({ name: 'og:site_name', content: 'abcd' });
                //this.meta.addTag({ name: 'og:og:type', content: 'article' });
                //this.meta.addTag({ name: 'og:url', content: 'https://mycompany.ogibiz.com/neweshops/productdetails/1258/en' });
                this.meta.addTag({ name: 'og:locale', content: 'en_US' });
                this.meta.addTag({ name: 'og:description', content: 'abcd descriptin here' });
              }


              // const src = item.product_image_thumbnails.extralarge;
              const src = this.ogiimgpath + 'tr:h-820/rep' + item.product_image_file;
              // const caption = 'Image ' + index + ' caption here';
              // const thumb = 'demo/img/image' + index + '-thumb.jpg';
              const album = {
                src: src,
                // caption: caption,
                // thumb: thumb
              };

              this._albums.push(album);
            }
          });
        this.mainImgArrCount = this._albums.length;


        this.generateArray(this.productdata.Configuration.ProductDetails['inventory_media']).forEach(
          (item, index) => {
            const album = {
              //src: item.product_image_thumbnails.extralarge
              src: this.ogiimgpath + 'tr:h-820/rep' + item.variation_image_file
            };
            this._albums.push(album);
          });



        this.currency = this.productdata.Configuration.ProductDetails.currency_code;
        const inventoryImgCount = Object.keys(
          this.productdata["Configuration"].ProductDetails.inventory_media
        ).length;
        this.product_img_listblock =
          (this.productdata["Configuration"].ProductDetails.media.length +
            inventoryImgCount) *
          132 +
          "px";

        if (this.productdata.Configuration.Page.design !== null) {
          console.log("product details called");
          this.product.setbackGround(
            this.productdata.Configuration.Page.design[
            ".page_background .page_bg"
            ]
          );
          this.product.getTranslatedTexts(this.username, this.language).subscribe((tx: Object) => {
            this.manageTexts = tx["CartDetailsInfo"].translatedTexts;
          });
        }
        this.update(this.formProductdetails);
        console.log(this.productdata);
        this.loading = false;
      });
  }

  update(formProductdetails) {
    //console.log(formProductdetails.value);
    this._disableAddToCart(false);
    this.visit_cart = true;

    this.variationStr = "";
    this.variationIdStr = "";
    this.temp_total = Number(
      this.quantity * this.productdata.Configuration.ProductDetails.discountPrice
    ).toFixed(2);

    

    if (!formProductdetails) {
      console.log('stock_staut' + this.productdata.Configuration.shop_configuration.stock_status.status);
      console.log('main_product_stock' + this.productdata.Configuration.ProductDetails.main_product_stock);
      console.log('product_stock_cnt' + this.productdata.Configuration.ProductDetails.product_stock_cnt);
      // loads when page is loaded
      this.disable_add_to_cart =
        this.productdata.Configuration.ProductDetails.product_stock_cnt > 0 ||
          this.productdata.Configuration.ProductDetails.main_product_stock > 0 ||
          this.productdata.Configuration.shop_configuration.stock_status.status <= 0
          ? false : true;

      if (this.productdata.Configuration.shop_configuration.stock_status.hide_basket == 0) {
        this.show_busket = true;
      } else {
        this.show_busket = false;
      }

      if (
        this.productdata.Configuration.ProductDetails.variations !== undefined
      ) {
        this.productdata.Configuration.ProductDetails.variations.forEach(
          (item, index) => {
            item.values.forEach((itemdata, indexvalue) => {
              if (indexvalue === 0) {
              //this.temp_total =
                  //parseFloat(this.temp_total) + parseFloat(itemdata.price);
                //this.temp_total = parseFloat(this.temp_total).toFixed(2);
                this.temp_total=parseFloat(this.temp_total).toFixed(2);
                if (this.variationStr === "") {
                  // this.variationStr = itemdata.choice;  // changed to choice_str on 18-03-2020  for variations name like 'XS;0-6';

                  // this.variationStr = itemdata.choice_str; // comneted on 27-07-2020- for deafulat select option to nothing selected
                  //  this.variationIdStr = itemdata.id; // comneted on 27-07-2020- for deafulat select option to nothing selected
                } else {
                  //this.variationStr = this.variationStr + "," + itemdata.choice; // changed to choice_str on 18-03-2020  for variations name like 'XS;0-6';

                  //   this.variationStr = this.variationStr + "," + itemdata.choice_str; // comneted on 27-07-2020- for deafulat select option to nothing selected
                  //  this.variationIdStr = this.variationIdStr + "," + itemdata.id; // comneted on 27-07-2020- for deafulat select option to nothing selected
                }
                // if ( this.productdata.Configuration.ProductDetails.inventory[this.variationStr]) {
                //   this._showStockLeftWithProductcode(this.variationStr);
                // }   //// 3 lines are comneted on 27-07-2020- for deafulat select option to nothing selected
              }
            });
            // jQuery('#variation_str').val(this.variationStr);
          }
        );



        this.disableQuantity = false;
        console.log('after load ' + this.variationStr);
        if (this.variationStr !== "") {
          // if product have variation
          this.__isAvailabeVariation(this.variationStr, true, false); // last paramers is to show alert or not
        } else {
          // if no variation , check the main product quantity
          this.__isAvailabeVariation(this.variationStr, false, false); // last paramers is to show alert or not
        }



      } else {
        this._showStockLeftWithProductcode(false, false);
      }
    } else {
      console.log(formProductdetails.value);
      this.disableQuantity = true;
      this.productdata.Configuration.ProductDetails.price = parseFloat(
        this.productdata.Configuration.ProductDetails.discountPrice
      ).toFixed(2);

      this.productdata.Configuration.ProductDetails.price = parseFloat(
        this.productdata.Configuration.ProductDetails.discountPrice
      ).toFixed(2);
      this.temp_total = Number(
        this.quantity * this.productdata.Configuration.ProductDetails.price
      );
      this.temp_total = parseFloat(this.temp_total).toFixed(2);

      this.varientsSelected = [];
      Object.keys(formProductdetails.value).forEach(key => {
        // alert(formProductdetails.value[key]);
        //  console.log(key); //key
        if (
          this.productdata.Configuration.ProductDetails.variations !== undefined
        ) {
          // console.log(this.productdata.Configuration.ProductDetails.variations);
          this.productdata.Configuration.ProductDetails.variations.forEach(
            (item, index) => {
              if (item.name === key) {
                item.values.forEach((itemdata, indexvalue) => {
                  if (itemdata.id === formProductdetails.value[key]) {
                    this.varientsSelected = this.varientsSelected.filter(
                      varient => {
                        if (varient.id === itemdata.id) {
                          return false;
                        }
                        return true;
                      }
                    );
                   
                    itemdata["VarientType"] = key;
                    // this.varientsSelected = [];
                    this.varientsSelected.push(itemdata);
                    // console.log(this.varientsSelected);
                    this.temp_total =
                      parseFloat(this.temp_total) +
                      parseFloat(itemdata.price) * this.quantity;
                    this.temp_total = parseFloat(this.temp_total).toFixed(2);
                    if (this.variationStr === "") {
                      this.variationStr = itemdata.choice_str;
                      // this.variationStr = itemdata.choice;
                      this.variationIdStr = itemdata.id;
                    } else {
                      this.variationStr = this.variationStr + "_" + itemdata.choice_str;
                      // this.variationStr =  this.variationStr + "," + itemdata.choice;
                      this.variationIdStr =
                        this.variationIdStr + "," + itemdata.id;
                    }
                  }
                });
              }
            }
          );
        }

      });
      // if (  this.productdata.Configuration.ProductDetails.variations !== undefined   &&  this.variationStr.length <= 0) {
      //   alert('Plesase select a variation');
      //   this._disableAddToCart(true);
      //       this.visit_cart = false;
      //       return false;
      //   }
      this.disableQuantity = false;
      console.log('here ' + this.variationStr);
      if (this.variationStr !== "") {
        // if product have variation
        this.__isAvailabeVariation(this.variationStr);
      } else {
        // if no variation , check the main product quantity
        this.__isAvailabeVariation(this.variationStr, false);
      }
    }
    this.variation_str = this.variationStr;
    this.variation_id_str = this.variationIdStr;
    this.main_total_price = this.temp_total;
    this.main_price = this.temp_total;
    this.variationStr = "";
    this.variationIdStr = "";

    let added=this.temp_total- parseFloat(this.productdata.Configuration.ProductDetails.discountPrice);
    this.spike_price = parseFloat(this.temp_price) + added;
 
    console.log(this.productdata.Configuration.ProductDetails.price)
  }

  __isAvailabeVariation(variationStr, hasvariations: Boolean = true, showAllert = true) {

    // hasvariations = true means check with variations array
    // otherwise check main product quantity

    if (hasvariations) {
      Object.keys(
        this.productdata.Configuration.ProductDetails.inventory
      ).forEach(key => {
        // alert('key' + key);
        // alert('variationStr' + variationStr);
        console.log(key +'==='+ variationStr);
        if (key === variationStr) {
         console.log('match key' + key);
         console.log('match variationStr' + variationStr);
          // console.log('here now ' + this.productdata.Configuration.ProductDetails.inventory[key].product_code);
          // console.log('here now ' + this.productdata.Configuration.ProductDetails.inventory[key].status);
          if (!this.productdata.Configuration.ProductDetails.inventory[key].status) {
            if (showAllert) {
              alert("This variation is not available right now !");
            }
            this._disableAddToCart(true);
            this.visit_cart = false;
            return false;
          }
          if ((
            this.productdata.Configuration.ProductDetails.inventory[key]
              .status === false ||
            this.productdata.Configuration.ProductDetails.inventory[key]
              .stock_left <= 0) && this.productdata.Configuration.shop_configuration.stock_status.status > 0
          ) {
            alert("This variation is not available right now !");
            this._disableAddToCart(true);
            this.visit_cart = false;
            return false;
          } else if ((
            this.productdata.Configuration.ProductDetails.inventory[key]
              .status === true &&
            this.productdata.Configuration.ProductDetails.inventory[key]
              .stock_left < this.quantity) && this.productdata.Configuration.shop_configuration.stock_status.status > 0
          ) {
            console.log(this.manageTexts);
            const stockAlertText = (this.manageTexts.product_item_not_in_stock && this.manageTexts.product_item_not_in_stock !== null && this.manageTexts.product_item_not_in_stock !== '') ? this.manageTexts.product_item_not_in_stock :
          ' items not in stock';
        
            alert(this.quantity + ' ' + stockAlertText);
            this.visit_cart = false;
            this._disableAddToCart(true);
            return false;
          } else {
            this._disableAddToCart(false);
            if (
              this.productdata.Configuration.ProductDetails.inventory[
              variationStr
              ]
            ) {
              this._showStockLeftWithProductcode(variationStr);
            }
            this.visit_cart = true;
          }
        }
      });
    } else {
      this.visit_cart = true;
      this._disableAddToCart(false);
      if (this.productdata.Configuration.ProductDetails.main_product_stock <= 0 &&
        this.productdata.Configuration.shop_configuration.stock_status.status > 0) {
        if (this.formProductdetails) {   // stop showing message on
          alert('This product is not available right now');
          this._showStockLeftWithProductcode(false, false);
          this.visit_cart = false;
          this._disableAddToCart(true);
          return false;
        }
      } else if (this.productdata.Configuration.ProductDetails.main_product_stock < this.quantity &&
        this.productdata.Configuration.shop_configuration.stock_status.status > 0) {
          console.log(this.manageTexts);
          const stockAlertText = (this.manageTexts.product_item_not_in_stock && this.manageTexts.product_item_not_in_stock !== null && this.manageTexts.product_item_not_in_stock !== '') ? this.manageTexts.product_item_not_in_stock :
          ' items not in stock';
        //alert(this.quantity + " items not in stock !");
        alert(this.quantity + ' '+stockAlertText);
        this._showStockLeftWithProductcode(false, false);
        this.visit_cart = false;
        this._disableAddToCart(true);
        return false;
      }
    }
  }

  _disableAddToCart($status = true) {
    this.disable_add_to_cart = $status;
  }
  _showStockLeftWithProductcode(variationStr, hasvariations: Boolean = true) {
    if (hasvariations) {
      this.variationData = this.productdata.Configuration.ProductDetails.inventory[this.variationStr
      ];
      this.stockleft = this.variationData["stock_left"];
      this.productcode = this.variationData["product_code"];
      //alert(variationStr.replace(/[, . | $ * +  # @ / !]/g, "_"));
      jQuery(".vari_" + variationStr.replace(/[, . | $ * +  # @ / ! ) (]/g, "_")).trigger("click");  //  space and , and . etc  is replaced with _ here
    } else {
      this.stockleft = this.productdata.Configuration.ProductDetails.main_product_stock;
      this.productcode = this.productdata.Configuration.ProductDetails.main_product_code;
    }
  }
  addtocart(formProductdetails) {
    console.log(formProductdetails.value);
    if (this.productdata.Configuration.ProductDetails.variations != undefined) {
      if (this.productdata.Configuration.ProductDetails.variations.length > 0) {
        if (formProductdetails.value.variation_str === '') {
          alert('Please select available variations');
          return false;
        } else {
          let selectedVarArr = formProductdetails.value.variation_str.split("_");
          //alert(this.productdata.Configuration.ProductDetails.variations.length+'== '+selectedVarArr.length);
          if (selectedVarArr.length < this.productdata.Configuration.ProductDetails.variations.length) {
            alert('Please select available variations');
            return false;
          }
          
        }
      }
    }
    this.update(formProductdetails);
    if (this.visit_cart) {
      this.addtocartPromise(formProductdetails).then(data => {
        this.ru.navigateByUrl(
          "/cart/" + JSON.parse(this.cookieService.get("defaultlang"))
        );
        // console.log("Prmise return", data);
      });
    }
  }

  private addtocartPromise(formProductdetails): Promise<any> {
    console.log('final data', formProductdetails.value);
    const cookieKey = "Cart_items";
    // tslint:disable-next-line:one-line
    // const existingcartprod = this.cookieService.check(cookieKey)
    //   ? JSON.parse(this.cookieService.get(cookieKey))
    //   : [];

    const existingcartprod = (localStorage.getItem(cookieKey) != null)
      ? JSON.parse(localStorage.getItem(cookieKey))
      : [];
    let tempvariationData = {};
    const products = existingcartprod.filter((product, key) => {
      // tslint:disable-next-line:max-line-length
      // if (product.variation_str + "-" + product.productid ===
      // formProductdetails.value.variation_str + "-" + formProductdetails.value.productid
      // ) {
      if (
        product.variation_id_str + "-" + product.productid ===
        formProductdetails.value.variation_id_str +
        "-" +
        formProductdetails.value.productid
      ) {
        return false;
      }
      return true;
    });


    tempvariationData =
      formProductdetails.value.variation_str !== undefined
        ? this.productdata.Configuration.ProductDetails.inventory[
        formProductdetails.value.variation_str
        ]
        : [];
    console.log('tempvariationData', tempvariationData);
    formProductdetails.value["stock_left"] = (tempvariationData === undefined) ? 0 : tempvariationData["stock_left"];
    formProductdetails.value["productcode"] = (tempvariationData === undefined) ? null : tempvariationData["product_code"];
    formProductdetails.value[
      "size"
    ] = this.productdata.Configuration.ProductDetails.size;
    formProductdetails.value[
      "weight"
    ] = this.productdata.Configuration.ProductDetails.weight;
    formProductdetails.value["SelectedVarient"] = this.varientsSelected;
    formProductdetails.value["image"] = this.getSelectedProductImage(formProductdetails.value);
    formProductdetails.value["main_product_stock"] = this.productdata.Configuration.ProductDetails.main_product_stock;

    console.log('final push from details', formProductdetails.value);
    products.push(formProductdetails.value);

    localStorage.setItem(cookieKey, JSON.stringify(products));
    //this.cookieService.set(cookieKey, JSON.stringify(products), 24 * 100, "/");
    // console.log("setting in cookie", products);
    return Promise.resolve(products);
  }
  getSelectedProductImage(formProductdetails) {
    if (
      Object.keys(this.productdata.Configuration.ProductDetails.inventory_media)
        .length > 0
    ) {
      const inventory_media = this.productdata.Configuration.ProductDetails[
        "inventory_media"
      ];

      this.generateArray(inventory_media).forEach((itemdata, indexvalue) => {
        if (itemdata.variation === formProductdetails.variation_str) {
          this.match_varation_str = this.ogiimgpath + 'tr:h-100/rep' +
            inventory_media[formProductdetails.variation_str].variation_image_file;
        }
      });

      if (this.match_varation_str !== undefined) {
        return this.match_varation_str;
      } else {
        return this.productdata.Configuration.ProductDetails.media["0"]
          .product_image;
      }
    } else {
      return this.productdata.Configuration.ProductDetails.media["0"].product_image;
    }
  }

  generateArray(obj) {
    return Object.keys(obj).map(key => obj[key]);
  }
  getLength(obj) {
    return Object.keys(obj).length;
  }
  fbs_click() {
    const prod_id = this.productdata.Configuration.ProductDetails.id;
    let cpage = this.currentPageUrl.replace('www.', "");
    console.log('cpage1',cpage);
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    console.log(baseUrl);
    console.log('prod_id',prod_id);
    // cpage = cpage.replace('https://', "");
    // cpage = cpage.replace(/[/]/g, "_");
    // if (cpage.includes('http:')) {
    //   cpage = cpage.replace('http:__', "");
    // }
    cpage = btoa(cpage);
    console.log('cpage',cpage);
    window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(baseUrl + '/neweshops/test.php?prod_id=' + prod_id + '&cpage=' + cpage),
      'sharer',
      'toolbar=0,status=0,width=626,height=436');
    return false;
  }

  twitter_share() {
    const prod_id = this.productdata.Configuration.ProductDetails.id;
    let cpage = this.currentPageUrl.replace('www.', "");
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    // cpage = cpage.replace('https://', "");
    // cpage = cpage.replace(/[/]/g, "_");
    // if (cpage.includes('http:')) {
    //   cpage = cpage.replace('http:__', "");
    // }
    cpage = btoa(cpage);
    var url = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(baseUrl + '/neweshops/test.php?prod_id=' + prod_id + '&cpage=' + cpage + '&from=twitter');
    window.open(url, 'TwitterWindow', 'toolbar=0,status=0,width=626,height=436');
    return false;
  }
}