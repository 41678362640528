import { PageblocksService } from '../pageblocks.service';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Title, Meta, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CookieService } from "ngx-cookie-service";

declare var jQuery: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [PageblocksService]
})
export class HomeComponent implements OnInit {
  // public homedata;
  @Input() homedata: any;
  @Input() descriptionData: any;
  @Input() homedataDetail: any;
  @Input() loading: boolean;
  

  public routerPar: any;
  public slug: string;
  public username: string;
  public language: string;
  public protected_error: Boolean = false;
  public errormessage = '';
  public searSuggessionKeys: any;
  customblock: any;
  customblockflag: boolean;


  constructor(private _sanitizer: DomSanitizer,public _homeBLocks: PageblocksService, public activatedRoute: ActivatedRoute, public ru: Router,
    private titleService: Title, private meta: Meta, private cookieService: CookieService) {
    const routerPar = this.activatedRoute.params.subscribe((params: Params) => {

      this.username = (params['username'] === '' || params['username'] === undefined) ? 'mycompany' : params['username'];
      this.slug = (params['slug'] === '' || params['slug'] === undefined) ? 'home' : params['slug'];

      this.language = (params['language'] === '' || params['language'] === undefined) ? null : params['language'];
      this.loading = true;
      if (this.language != null && (this.language !== JSON.parse(this.cookieService.get('defaultlang')))) {
        this.cookieService.set('defaultlang', JSON.stringify(this.language), 1, '/');
      }

      this.loadData();
    });
  }
  
  ngOnInit() {
    //  this.loadData();
    // this.resetFavicon();
  }
  // public resetFavicon(): void {
  //   console.log("Resetting favicon");
  //   this.favicons.reset();
  // }
  public useFavicon(name: string): void {
    console.log("Activating favicon:", name);
  }
  loadData(orderdata: string = 'newest',searchby: any = null) {
  //  / alert(orderdata);

    this._homeBLocks.getPageBlocks(this.username, this.slug, orderdata,searchby)
      .subscribe((data: Object) => {
        this.homedataDetail = data;
        if (this.homedataDetail.Configuration.Page.category_products === undefined &&
          this.homedataDetail.Configuration.Page.block.length <= 0) {
          if (this.homedataDetail.Configuration.Page.page_id == null) {
            this.ru.navigateByUrl('/404');
            return false;
          }
        }
        // alert('hre');
        this.homedata = this.homedataDetail.Configuration.Page.block;
        //console.log("Log data", this.homedataDetail);


        this.customblock=this.homedataDetail.Configuration.Page.details.meta.custom_html_code;
        this.customblock= this.customblock ? this.htmlProperty(this.customblock):false;
        this.customblockflag=true;

        // =============== meta and title ===============
        this.meta.removeTag("name='title'");
        this.meta.removeTag("name='description'");
        this.meta.removeTag("name='keywords'");
        this.meta.removeTag("name='og:image'");

        // if (this.homedataDetail.Configuration.Page.details.pagename !== '' &&
        // this.homedataDetail.Configuration.Page.details.pagename !== undefined) {
        //   this.titleService.setTitle(this.homedataDetail.Configuration.Page.details.pagename);
        // }
        //   console.log('met' + this.homedataDetail);
        if (this.homedataDetail.Configuration.Page.details.meta.meta_title !== '' &&
          this.homedataDetail.Configuration.Page.details.meta.meta_title !== undefined) {
            console.log(this.homedataDetail);
          this.meta.addTag({ name: 'title', content: this.homedataDetail.Configuration.Page.details.meta.meta_title });
          this.titleService.setTitle(this.homedataDetail.Configuration.Page.details.meta.meta_title);
        } if (this.homedataDetail.Configuration.Page.details.meta.meta_desc !== undefined &&
          this.homedataDetail.Configuration.Page.details.meta.meta_desc !== '') {
          this.meta.addTag({ name: 'description', content: this.homedataDetail.Configuration.Page.details.meta.meta_desc });
        } if (this.homedataDetail.Configuration.Page.details.meta.meta_tags !== '' &&
          this.homedataDetail.Configuration.Page.details.meta.meta_tags !== undefined) {
          this.meta.addTag({ name: 'keywords', content: this.homedataDetail.Configuration.Page.details.meta.meta_tags });
        }
        if (this.homedataDetail.Configuration.Page.details.meta.meta_img !== null &&
          this.homedataDetail.Configuration.Page.details.meta.meta_img !== '' &&
          this.homedataDetail.Configuration.Page.details.meta.meta_img !== undefined) {
          this.meta.addTag({ name: 'og:image', content: this.homedataDetail.Configuration.Page.details.meta.meta_img.replace('www.', '') });
          // console.log('img with path' , this.homedataDetail.Configuration.Page.details.meta.meta_img);
          // this.useFavicon(this.homedataDetail.Configuration.Page.details.meta.meta_img);
        }

        if (this.slug === 'home' && this.homedataDetail.Configuration.Page.details.meta.custom_html_code.length > 0) {
          jQuery("head").append(this.homedataDetail.Configuration.Page.details.meta.custom_html_code);
        }
        // ============== end meta and title ===============
        // if (this.homedata.length <= 0 ) {
        //   this.ru.navigateByUrl('/404');
        // } else {
        if (this.homedataDetail.Configuration.Page.design !== null) {
          this._homeBLocks.setbackGround(this.homedataDetail.Configuration.Page.design[".page_background .page_bg"]);
        }
        this.loading = false;

        if (this.homedataDetail.Configuration.Page.is_password_protected === true) {
          // console.log(JSON.parse(this.cookieService.get('pageid_' + this.homedataDetail.Configuration.Page.page_id)).length);
          const this_pageprotection = JSON.parse(this.cookieService.get('pageid_' + this.homedataDetail.Configuration.Page.page_id));
          if (this_pageprotection.length > 0) {
            this.homedataDetail.Configuration.Page.is_password_protected = false;
          }
        }
        // alert(this.slug);
      });
  }
  htmlProperty(customblock: any): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(customblock);
  }

  checkPageprotection(pagecodeData) {
    this.protected_error = false;
    if (pagecodeData.value.page_password.length <= 0) {
      this.protected_error = true;
      this.errormessage = 'Please proviede passwod !';
      // alert('Please proviede passwod !');
      return false;
    } else if (pagecodeData.value.page_password === this.homedataDetail.Configuration.Page.page_password) {
      this.cookieService.set('pageid_' + this.homedataDetail.Configuration.Page.page_id,
        JSON.stringify(pagecodeData.value.page_password), 1); // setting it for 1 day
      this.homedataDetail.Configuration.Page.is_password_protected = false;
    } else {
      this.protected_error = true;
      this.errormessage = 'Password missmatched !';
      // alert('Password missmatched !');
      return false;
    }
  }
  shortProductByData(shortSearchObj: any) {
    console.log(shortSearchObj);
    this.loadData(shortSearchObj.shortby,shortSearchObj.searchby);
    
  }
  getSearchKeys(searchKey:any){
    
    alert('call api to get product names');
    console.log(searchKey);

    this._homeBLocks.getSearchSuggionData(this.username, this.slug, searchKey)
    .subscribe((searchKeyData: Object) => {
      this.searSuggessionKeys = searchKeyData;
      console.log('aa',searchKeyData);
      // if (this.homedataDetail.Configuration.Page.category_products === undefined &&
      //   this.homedataDetail.Configuration.Page.block.length <= 0) {
     
      // }
    });

  }
}

