import { Http } from '@angular/http';
import { Component, OnInit, Input, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-fullpagebackground',
  templateUrl: './fullpagebackground.component.html',
  styleUrls: ['./fullpagebackground.component.scss']
})
export class FullpagebackgroundComponent implements OnInit {
  @Input() content: any;
  constructor() { }

  ngOnInit() {
  }

}
