import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-maindescripton',
  templateUrl: './maindescripton.component.html',
  styleUrls: ['./maindescripton.component.scss']
})


export class MaindescriptonComponent implements OnInit {
  @Input() descriptionData: any;

  public description: any;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.description = this.descriptionData;
  }

}
