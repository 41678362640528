import { Http } from '@angular/http';
import { Component, OnInit, Input, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  @Input() content: any;
  public thisObj:any;
  public toshow:any;
  public closestOuterDiv:any;
  public panel:any;
  constructor(public sanitizer: DomSanitizer) { }



  ngOnInit() {
  }

  manageaccordion(item_identity = null) {
    console.log(item_identity);
    this.thisObj = $(this);
    this.toshow = $('.'+item_identity).next('.panel');
    this.closestOuterDiv = $('.'+item_identity).closest('.accordion_block');
    $('.panel').hide();

    $('.panel').each(function (index) {
      this.panel = $(this);
      if (this.panel.height()) {
        this.panel.css('height', '');
      }
    });
    if ($('.'+item_identity).hasClass('active')) {
      console.log('reove ac');
      $('.'+item_identity).removeClass('active');
      this.toshow.css('height', '');
      this.toshow.hide('slow');
    } else {
      console.log('add ac');
      $('.'+item_identity).addClass('active');
      this.toshow.show('slow');
      this.toshow.css('height', this.toshow.height() + 'px');
    }
  }


}
