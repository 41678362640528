import { Http } from '@angular/http';
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { CookieService } from "ngx-cookie-service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { PageblocksService } from "../../pageblocks.service";



declare var $: any;
@Component({
  selector: "app-showcase",
  templateUrl: "./showcase.component.html",
  styleUrls: ["./showcase.component.scss"],
  providers: [PageblocksService]
})
// @Component({
//   selector: 'image',
//   template: '<img [defaultImage]="defaultImage" [lazyLoad]="image" [offset]="offset">'
// })
// class ImageComponent {
//   defaultImage = 'https://www.placecage.com/1000/1000';
//   image = 'https://images.unsplash.com/photo-1443890923422-7819ed4101c0?fm=jpg';
//   offset = 100;
// }
export class ShowcaseComponent implements OnInit {
  varientsSelected: Array<any> = [];
  defaultImage = "assets/images/Dual_Ring-1s-90px.gif";
  // image = "https://images.unsplash.com/photo-1443890923422-7819ed4101c0?fm=jpg";
  offset = 1000;

  @Input() content: any;
  @Input() shopconfiguration: any;
  @Input() translatedData: any;


  showfulldescription = false;
  public default_lang = "";
  public productdata: any;
  public selectedProductImage: any;
  public selectedProductTitle: any;
  public formProductdetails: any;
  public variation_id_str: any;
  public variation_str: any;
  public variationStr: string;
  public variationIdStr: string;

  constructor(
    public ru: Router,
    public sanitizer: DomSanitizer,
    private cookieService: CookieService,
    private http: Http,
    public product: PageblocksService
  ) {
    this.default_lang = (this.cookieService.get("defaultlang").length > 0) ? JSON.parse(this.cookieService.get("defaultlang")) : '';
    this.formProductdetails = false;
  }
  ngOnInit() { 
    if(this.shopconfiguration.stock_status.hide_basket == 1){
      this.shopconfiguration.stock_status.hide_basket = true;
    }else{
      this.shopconfiguration.stock_status.hide_basket = false;
    }
  }

  showFullDesc(e) {
    $(e.target).closest(".bg-box").find(".overlay_bg,.viewmore").css({ display: "inline-block" });
  }
  hideFullDesc(e) {
    // e.target.style.display = "none";
    $(e.target)
      .closest(".bg-box").find(".overlay_bg,.viewmore").css({ display: "none" });
  }

  openPageLink(link) {
    console.log(link);
    if (link !== "false") {
      window.open(link, "_top");
    }
  }
  openProductAddedModal(productid) {
    this.formProductdetails = {};
    this.product.getProductDetails(productid).subscribe((dataProductDet: Object) => {
      this.productdata = dataProductDet;
      // console.log('product details', this.productdata);
      // ======================== setting modal data ===============================
      this.selectedProductTitle = this.productdata.Configuration.ProductDetails.name;
      this.productdata.Configuration.ProductDetails.media.forEach((mediaData, mediaIndex) => {
        if (mediaData.is_default) {
          this.selectedProductImage = mediaData.product_image;
        }
      });
      // ======================= end setting modal data ===========================

      // ======================= creating the cart data ===========================
      this.formProductdetails["productcode"] = this.productdata.Configuration.ProductDetails.main_product_code;
      this.formProductdetails["stock_left"] = this.productdata.Configuration.ProductDetails.main_product_stock;

      this.formProductdetails["productid"] = this.productdata.Configuration.ProductDetails.id;
      this.formProductdetails["size"] = this.productdata.Configuration.ProductDetails.size;
      this.formProductdetails['currency'] = this.productdata.Configuration.ProductDetails.currency_code;
      this.formProductdetails["weight"] = this.productdata.Configuration.ProductDetails.weight;
      this.formProductdetails['image'] = this.selectedProductImage;
      this.formProductdetails['main_product_stock'] = this.productdata.Configuration.ProductDetails.main_product_stock;
      this.formProductdetails['quantity'] = 1;
      this.formProductdetails['total_price'] = parseFloat(this.productdata.Configuration.ProductDetails.price);
      this.formProductdetails['SelectedVarient'] = [];
      // ================SelectedVarient if any===================================
      if (this.productdata.Configuration.ProductDetails.variations !== undefined &&
        this.productdata.Configuration.ProductDetails.variations.length > 0) {
          const alertText  = (this.translatedData.product_variation_alert_text && this.translatedData.product_variation_alert_text !== null && this.translatedData.product_variation_alert_text	 !== '') ? this.translatedData.product_variation_alert_text	:
          'This product has variations. Please select from the available options' ;
        
          alert(alertText);
            window.open('./productdetails/'+productid+'/'+this.default_lang, "_top");
            return false;
            
        this.varientsSelected = [];
        this.variationStr = this.variationIdStr = "";
        this.productdata.Configuration.ProductDetails.variations.forEach((variationData, variationKey) => {
          variationData.values.forEach((variationDataDetails, indexvalue) => {
            if (indexvalue > 0) {
              return true;
            }
            variationDataDetails["VarientType"] = variationData.name;

            // ================== variation selected id string and choice string =======
            if (this.variationStr === "") {
              this.variationStr = variationDataDetails.choice;
              this.variationIdStr = variationDataDetails.id;
            } else {
              this.variationStr = this.variationStr + "," + variationDataDetails.choice;
              this.variationIdStr = this.variationIdStr + "," + variationDataDetails.id;
            }

            this.variation_str = this.variationStr;
            this.variation_id_str = this.variationIdStr;
            // ================== variation selected id string and choice string =======

            this.varientsSelected.push(variationDataDetails);
          });
        });
        this.formProductdetails['variation_id_str'] = this.variation_id_str;
        this.formProductdetails['variation_str'] = this.variation_str;
        this.formProductdetails['SelectedVarient'] = this.varientsSelected;




        const temptotal = this.formProductdetails['total_price'] +
          ((this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']] !== undefined)
            ? parseFloat(this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']].additional_price)
            : 0);

        this.formProductdetails['total_price'] = parseFloat(temptotal).toFixed(2);
        // variation combination productcode and stock_left
        this.formProductdetails['productcode'] =
          (this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']] !== undefined)
            ? this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']].product_code : null;
        this.formProductdetails['stock_left'] =
          (this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']] === undefined) ?
            0 :
            this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']].stock_left;

      }
      // ================end SelectedVarient if any===================================


      // ============== check and setting up the cookie ====================
      const cookieKey = "Cart_items";
     // const existingcartprod = this.cookieService.check(cookieKey)? JSON.parse(this.cookieService.get(cookieKey)): [];

        const existingcartprod =   (localStorage.getItem(cookieKey) != null) 
      ? JSON.parse(localStorage.getItem(cookieKey))
      : []; 
      
      const products = existingcartprod.filter((product, key) => {
        if (product.variation_id_str + "-" + product.productid ===
          this.variation_id_str + "-" + this.formProductdetails["productid"]
        ) {
          return false;
        }
        return true;
      });

      products.push(this.formProductdetails);
      //this.cookieService.set(cookieKey, JSON.stringify(products), 24 * 100, "/");
      localStorage.setItem(cookieKey, JSON.stringify(products));
      $(".cartCount").html("(" + products.length + ")");
      // console.log('after set cookie', JSON.parse(this.cookieService.get(cookieKey)));
      // =============== end check and setting up the cookie ================


      // console.log('from product cat', this.formProductdetails);
      // =====================end creating the cart data ==========================

      // ======= open modal ===============
      $('#instantBuy').modal({
        show: 'true'
      });
      // ========= end open modal ===========
    });
  }

}
