import { PageblocksService } from "../pageblocks.service";
import { Component, OnInit, Input, NgModule } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { AsyncPipe } from "@angular/common";
import { Observable } from 'rxjs';
import { DomSanitizer, Meta, Title} from '@angular/platform-browser';
import {Http} from '@angular/http';
import { environment } from "../../environments/environment";

declare var jQuery: any;


@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"]
})
export class CartComponent implements OnInit {
  @Input() loading: boolean;
  @Input() locationdata: object;

  products: Promise<any>;
  public checkoutweight: any = 0;
  public tempweight: any = 0;
  public shippingdata: object;
  public setshipping: object;
  public username: string;
  public product_total_amount: any = 0;
  public shipping_charge: any = 0;
  public totalcheckoutprice: any = 0;
  public pricecurrency: any;
  public countrycode: string;
  public default_lang: String = "";
  public disableQuantity: Boolean = false;
  public manageTexts: any;
  public ogiimgpath= environment.ogiimageendpoint;
  public disable_checkout = false;
  public fullProductDetails = [];
  public total_cart_product_count: any = 0;
  public cartdataDetail: any;
  public slug: string;

  constructor(
    public ru: Router,
    public activatedRoute: ActivatedRoute,
    public product: PageblocksService,
    private cookieService: CookieService,
    private _http: Http,
    public sanitizer: DomSanitizer,
    private meta: Meta,
    private titleService: Title,
  ) {

    const routerPar = this.activatedRoute.params.subscribe((params: Params) => {
      this.username = (params['username'] === '' || params['username'] === undefined) ? 'mycompany' : params['username'];
      this.slug =
        params["slug"] === "" || params["slug"] === undefined
          ? null
          : params["slug"];
});
    this.loading = true;
    this.locationdata = {};
    this.default_lang = JSON.parse(this.cookieService.get('defaultlang'));
  }
  ngOnInit() {
    // this.username =  environment.production === false
    // ? "mycompany"
    // : window.location.host.split(".")[0] === ""
    //   ? "mycompoany"
    //   : window.location.host.split(".")[0];
    console.log(this.ogiimgpath);


      if (environment.production === false) {
        this.username = "mycompany";
      } else {
        if (window.location.host.split(".")[0] === "") {
          this.username = "mycompoany";
        } else {
          this.product.getusernamebyurl().subscribe((hostdata: Object) => {
            this.username = hostdata["hostDetails"];
          });
        }
      }


    this.manageTexts = this.product.getTranslatedTexts(this.username).subscribe((tx: Object) => {
      this.manageTexts = tx['CartDetailsInfo'].translatedTexts;
      if (tx['CartDetailsInfo'].Page.design !== null) {
        this.product.setbackGround(tx['CartDetailsInfo'].Page.design[".page_background .page_bg"]);
      }
    });
    this.products = this.getCartProducts();
    // console.log(this.products);
   // this.calculateCartItemCount(this.products);
    this.loading = false;
    this.loadData();
  }
  loadData(orderdata: string = 'newest',searchby: any = null) {
    this.product.getPageBlocks(this.username, this.slug, orderdata,searchby)
      .subscribe((data: Object) => {
        this.cartdataDetail = data;
        if(this.cartdataDetail.Configuration.Page.details == undefined){
          this.product.getPageBlocks(this.username, 'home', orderdata,searchby)
          .subscribe((edata: Object) => {
            this.cartdataDetail = edata;
            this.meta.removeTag("name='title'");
            this.meta.addTag({ name: 'title', content: this.cartdataDetail.Configuration.Page.details.meta.meta_title });
            this.titleService.setTitle(this.cartdataDetail.Configuration.Page.details.meta.meta_title);
          });
        }
        if(this.cartdataDetail.Configuration.Page.details !== undefined){
          this.meta.removeTag("name='title'");
          this.meta.addTag({ name: 'title', content: this.cartdataDetail.Configuration.Page.details.meta.meta_title });
          this.titleService.setTitle(this.cartdataDetail.Configuration.Page.details.meta.meta_title);
        }
        // console.log('meta_title',this.cartdataDetail.Configuration.Page.details.meta);
    });
  }
  public deleteFromcart(product, index) {
    this.products = this.deleteCartProduct(product).then(data => {
      return this.getCartProducts();
    });
  }
  public updateProductCount(numberofproduct, product) {
    this.disableQuantity = true;
    this.products = this.updateProductItem(
      numberofproduct,
      product
    ).then(data => {
      
      return this.getCartProducts();
      
        // return this.getCartProducts().then(returnCartProduct => {
        //   this.disableQuantity = false;
        // });
    });
  }

  private getCartProducts(): Promise<any> {
    const cookieKey = "Cart_items";
//      console.log(this.cookieService.get(cookieKey));
    // const existingcartprod = this.cookieService.check(cookieKey)
    //   ? JSON.parse(this.cookieService.get(cookieKey))
    //   : [];
      const existingcartprod = (localStorage.getItem(cookieKey) != null)
      ? JSON.parse(localStorage.getItem(cookieKey))
      : [];
    let checkoutprice = 0;
    let checkoutweight = 0;
    let checkoutsize = 0;

    
    let pricecurrency =  "USD";
    if (existingcartprod.length > 0) {
      this.pricecurrency = pricecurrency = existingcartprod[0].currency;
    }
    this.total_cart_product_count = 0;
    const products  = existingcartprod.map(extractedproduct => {
      
      this.product
        .getProductDetails(extractedproduct.productid, null)
        .toPromise()
        .then((prod: any) => {
          this.fullProductDetails = prod.Configuration;
          const productdetails =   prod.Configuration.ProductDetails;
          this.pricecurrency = productdetails.currency_code;
          if (extractedproduct.productid === productdetails.id) {
            // extractedproduct.image = productdetails.media["0"].product_image;
            extractedproduct.name = productdetails.name;
            extractedproduct.slug = productdetails.product_slug;
            extractedproduct.price = productdetails.price;
            extractedproduct.priceWithVariant =  extractedproduct.total_price / extractedproduct.quantity;
  
 
if (extractedproduct.SelectedVarient.length > 0) {
 extractedproduct.SelectedVarient.filter((cookVarient, selectedVariantKey) => {
            // console.log(cookVarient.id + cookVarient.VarientType +  '-- :' + cookVarient.choice );
            productdetails.variations.filter((produtdetailsVariation , proDetailsVariationKey ) => { // fetched Product data
              // console.log('produtdetailsVariation.values', produtdetailsVariation.values);
                produtdetailsVariation.values.filter((valuesData, valuesKey) => { // loop fatched product details variations.values note
                //  console.log('valuesData', valuesData);
                //  if (produtdetailsVariation.name === cookVarient.VarientType ) {
                    if ( cookVarient.id === valuesData.id )  { // matching if cookie data id == product data id
                      cookVarient.VarientType = produtdetailsVariation.name; // overwritting data by language
                      cookVarient.choice = valuesData.choice;  // overwritting data by language
                    }
                //   }
                });
              });
            });
}

          }
          this.disableQuantity = false;
        });
      checkoutprice = checkoutprice + +extractedproduct.total_price;
      checkoutweight = checkoutweight + +(extractedproduct.weight * extractedproduct.quantity);
      checkoutsize = checkoutsize + +(extractedproduct.size * extractedproduct.quantity);
      this.product_total_amount = checkoutprice;
      this.total_cart_product_count = Number(this.total_cart_product_count) + Number(extractedproduct.quantity);
      console.log('final', extractedproduct);
     // this.disableQuantity = false;
      return extractedproduct;
    });

    //  this.countrycode = 'IN';   // REMOVE THIS LINE WHILE MAKING LIVE

    if ( this.countrycode === undefined) {
        this.getLocationDetails().then((Response) => {
          this.locationdata  = Response;
          //  console.log('Response', Response);
          //  console.log('Response c', Response.location.country);
          this.shippingdata = this.getshipping(checkoutprice, checkoutsize, checkoutweight, Response.location.country);
        });
      }
      if ( this.countrycode !== undefined) {
        this.shippingdata = this.getshipping(checkoutprice, checkoutsize, checkoutweight, this.countrycode);
      }
      console.log(products);
      jQuery(".cartCount").html("(" + this.total_cart_product_count + ")");
     // jQuery(".cartCount").html("(" + existingcartprod.length + ")");
      

    return Promise.resolve({
      products: products,
      checkoutprice: checkoutprice,
      currency: pricecurrency,
      checkoutweight: checkoutweight,
      checkoutsize: checkoutsize
    });
  }
  private deleteCartProduct(DeleteProduct): Promise<any> {
    const cookieKey = "Cart_items";
    // const existingcartprod = this.cookieService.check(cookieKey)
    //   ? JSON.parse(this.cookieService.get(cookieKey))
    //   : [];
      const existingcartprod = (localStorage.getItem(cookieKey) != null)
      ? JSON.parse(localStorage.getItem(cookieKey))
      : [];
    const products = existingcartprod.filter((product, key) => {
      // tslint:disable-next-line:max-line-length
      if (
        product.variation_str + "-" + product.productid ===
        DeleteProduct.variation_str + "-" + DeleteProduct.productid
      ) {
        return false;
      }
      return true;
    });
    //this.cookieService.set(cookieKey, JSON.stringify(products), (24 * 100) , '/');
    console.log(JSON.stringify(products));
    localStorage.removeItem(cookieKey);
    localStorage.setItem(cookieKey, JSON.stringify(products));
    console.log("setting in cookie", products);
    return Promise.resolve(products);
  }

  private updateProductItem(numberofproduct, UpdateProduct) {
   // this.disable_checkout = false;
   this.total_cart_product_count =0;
    const cookieKey = "Cart_items";
    //const existingcartprod = this.cookieService.check(cookieKey) ? JSON.parse(this.cookieService.get(cookieKey)) : [];
    const existingcartprod = (localStorage.getItem(cookieKey) != null) ? JSON.parse(localStorage.getItem(cookieKey)): [];
    const products = existingcartprod.filter((product, key) => {
      // tslint:disable-next-line:max-line-length
      if (product.SelectedVarient.length > 0) {
        if (
          product.variation_str + "-" + product.productid ===
          UpdateProduct.variation_str + "-" + UpdateProduct.productid
        ) {
          if (((Number(product.stock_left) < numberofproduct) ) && this.fullProductDetails['shop_configuration'].stock_status.status > 0) {
            const stockAlertText = (this.manageTexts.product_item_not_in_stock && this.manageTexts.product_item_not_in_stock !== null && this.manageTexts.product_item_not_in_stock !== '') ? this.manageTexts.product_item_not_in_stock :
            ' items not in stock';
          
              alert(numberofproduct+ ' ' + stockAlertText);
            //alert(numberofproduct + ' items not in stock !');
            //this.disable_checkout = true; 
          } else {
            this.disable_checkout = false;
            product.quantity = numberofproduct;
            product.total_price = UpdateProduct.priceWithVariant * numberofproduct;
           }
        }
      } else {
        if ((Number(product.main_product_stock) < numberofproduct ) && this.fullProductDetails['shop_configuration'].stock_status.status > 0) {
          const stockAlertText = (this.manageTexts.product_item_not_in_stock && this.manageTexts.product_item_not_in_stock !== null && this.manageTexts.product_item_not_in_stock !== '') ? this.manageTexts.product_item_not_in_stock :
            ' items not in stock';
          
              alert(numberofproduct+ ' ' + stockAlertText);
          //alert(numberofproduct + ' items not in stock !');
          //this.disable_checkout = true;
        } else {
          if (
            product.productid ===UpdateProduct.productid
          ) {
            this.disable_checkout = false;
            product.quantity = numberofproduct;
            product.total_price = UpdateProduct.priceWithVariant * numberofproduct;
          }
         }
      }
      this.total_cart_product_count = Number(this.total_cart_product_count) + Number(product.quantity);
      console.log('here  '+ this.total_cart_product_count);
      return true;
    });
    //this.cookieService.set(cookieKey, JSON.stringify(products), (24 * 100) , '/');
    localStorage.setItem(cookieKey, JSON.stringify(products));
   // console.log("setting in cookie", products);
    return Promise.resolve(products);
  }

  private getshipping(checkoutprice, checkoutsize, checkoutweight, countrycodeStr): Promise<any> {
    return this.product.getshippingcost(checkoutprice, checkoutsize, checkoutweight, countrycodeStr ).toPromise();
  }
  checkout(formCart) {
   // console.log(this.shippingdata);
    // console.log(formCart.value);
    this.ru.navigateByUrl('/checkout/' + JSON.parse(this.cookieService.get('defaultlang')));
  }
  getLocationDetails() {
    return this._http
     .get("https://ipinfo.io")
     .toPromise()
     .then((response) => {
       this.countrycode = response.json().country;
       return {location: response.json()};
     });
     }
}
