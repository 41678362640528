import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from "ngx-cookie-service";
import { PageblocksService } from "../pageblocks.service";
import { Router, ActivatedRoute, Params } from "@angular/router";



declare var $: any;

@Component({
  selector: 'app-productcategory',
  templateUrl: './productcategory.component.html',
  styleUrls: ['./productcategory.component.scss'],
  providers: [PageblocksService]
})
export class ProductcategoryComponent implements OnInit {
  keyword = 'product_name';
  @Output() searchsuggetion: EventEmitter<any> = new EventEmitter<{ searchby: string }>();
  @Output() orderbythis: EventEmitter<any> = new EventEmitter<{ shortby: string, searchby: string }>();


  varientsSelected: Array<any> = [];

  @Input() content: any;
  @Input() loading: boolean;
  @Input() shopconfiguration: any;
  @Input() searSuggessionKeys: any;
  public  p: number = 1;
  public default_lang: String = "";
  public shortby_value: String = 'newest';
  public productdata: any;
  public selectedProductImage: any;
  public selectedProductTitle: any;
  public formProductdetails: any;
  public variation_id_str: any;
  public variation_str: any;
  public variationStr: string;
  public variationIdStr: string;
  public instantBuyProductid: any;
  // public searchText: any = '';
  public searchKeySuggestion: any;
  public search_term: any = '';
  public itemsPerPage:number=20

  showfulldescription = false;
  selesp: any;
  redirect: boolean=false;
  placeholder: any='';
  searchStyle: any;
  framebackground: any={'background-color':'none'};
  textColor: { color: any; };
  blocks: void;
  havecategoryblock: boolean=true;
  constructor(
    public ru: Router,
    public sanitizer: DomSanitizer,
    private router: Router,
    private cookieService: CookieService,
    public product: PageblocksService,
  ) {
    this.default_lang = JSON.parse(this.cookieService.get('defaultlang'));
    this.formProductdetails = false;
  }

  ngOnInit() {
    if (this.shopconfiguration.stock_status.hide_basket == 1) {
      this.shopconfiguration.stock_status.hide_basket = true;
    } else {
      this.shopconfiguration.stock_status.hide_basket = false;
    }

    console.log('swqs',this.content);
    this.blocks=this.content.Configuration.Page.block;
    console.log('swqs 1',this.blocks);
    this.checkcategory(this.blocks);
    this.getSeacrhConfiguration();
  }

  checkcategory(blocks){
    let flag=true;
    blocks.forEach(element => {
      console.log(element);
      if(element.block.blockname=='category_product' && flag==true){
        flag=false;
      }
    });
    this.havecategoryblock=flag;
  }
  showFullDesc(e,p) {
    this.selesp=p;
    this.redirect=false;
    setTimeout(() => {
      this.redirect=true;
    }, 1000);
    // $(e.target).next('.shot_desctiption_fulltext').css({ "display": "block" });
    // $(e.target).closest('.bg-box').find('.viewmore').css({"display": "block"});
    $(e.target).closest(".bg-box").find(".overlay_bg,.viewmore").css({ display: "inline-block" });
  }
  
  hideFullDesc(e,p) {
    this.selesp=p;
    // e.target.style.display = 'none';
    // $(e.target).closest('.bg-box').find('.viewmore').css({"display": "none"});
    $(e.target).closest(".bg-box").find(".overlay_bg,.viewmore").css({ display: "none" });
  }
  openPageLink(link) {
    // console.log(link);
    if (link !== 'false') {
      window.open(link, "_top");
    }
  }
  shortProductBy(shortby: any, searchby: any = null) {
    this.loading = true;
    this.shortby_value = '';
    this.shortby_value = shortby;

    if (this.searSuggessionKeys != undefined) {
      this.searSuggessionKeys.forEach((suggessionData, suggessonIndex) => {
        if (suggessionData.product_name == searchby) {
          searchby = suggessionData.product_name_default;
        }
      });
    }
    if (searchby != null || searchby != '') {
      this.orderbythis.emit({ shortby, searchby });
    } else {
      this.orderbythis.emit({ shortby });
    }


    this.loading = false;
  }
  searchproduct(shortby: any, searchby: any = null) {
    console.log(searchby);
    this.loading = true;
    this.shortProductBy(shortby, searchby);
    this.loading = false;
  }
  toogleSort() {
    $('.short_options_toggle').toggle('slow');
    // this.short_options_toggle =  this.short_options_toggle === 'hide' ? 'show' : 'hide';
  }

  getSeacrhConfiguration(){
    this.product.getCategorySearch().subscribe((dataCategoryDet: Object) => {
      console.log(dataCategoryDet);
      this.placeholder=dataCategoryDet['categorySearch']['text'];
      this.searchStyle=dataCategoryDet['categorySearch'];
      this.framebackground={'background-color':dataCategoryDet['categorySearch']['background-color']};

      this.textColor=dataCategoryDet['categorySearch']['css_style']['search_text_color'];
      // content.Configuration.Page.category_products.design.css_style['.product .breadcrumb_sort_textcolor']
      
    });
  }

  openProductAddedModal(productid) {
    this.formProductdetails = {};
    this.product.getProductDetails(productid).subscribe((dataProductDet: Object) => {
      this.productdata = dataProductDet;
      // console.log('product details', this.productdata);
      // ======================== setting modal data ===============================
      this.selectedProductTitle = this.productdata.Configuration.ProductDetails.name;
      this.productdata.Configuration.ProductDetails.media.forEach((mediaData, mediaIndex) => {
        if (mediaData.is_default) {
          this.selectedProductImage = mediaData.product_image;
        }
      });
      // ======================= end setting modal data ===========================

      // ======================= creating the cart data ===========================
      this.formProductdetails["productcode"] = this.productdata.Configuration.ProductDetails.main_product_code;
      this.formProductdetails["stock_left"] = this.productdata.Configuration.ProductDetails.main_product_stock;

      this.formProductdetails["productid"] = this.productdata.Configuration.ProductDetails.id;
      this.formProductdetails["size"] = this.productdata.Configuration.ProductDetails.size;
      this.formProductdetails['currency'] = this.productdata.Configuration.ProductDetails.currency_code;
      this.formProductdetails["weight"] = this.productdata.Configuration.ProductDetails.weight;
      this.formProductdetails['image'] = this.selectedProductImage;
      this.formProductdetails['main_product_stock'] = this.productdata.Configuration.ProductDetails.main_product_stock;
      this.formProductdetails['quantity'] = 1;
      this.formProductdetails['total_price'] = parseFloat(this.productdata.Configuration.ProductDetails.discountPrice);
      this.formProductdetails['SelectedVarient'] = [];
      // ================SelectedVarient if any===================================
      if (this.productdata.Configuration.ProductDetails.variations !== undefined &&
        this.productdata.Configuration.ProductDetails.variations.length > 0) {
        //  if variations are there, we will take to prodduct details page, next 3 lines,  lines below it are not requierd now.

        const alertText = (this.content.Configuration.TranslatedText.product_variation_alert_text && this.content.Configuration.TranslatedText.product_variation_alert_text !== null && this.content.Configuration.TranslatedText.product_variation_alert_text !== '') ? this.content.Configuration.TranslatedText.product_variation_alert_text :
          'This product has variations. Please select from the available options';
        alert(alertText);
        //alert('This product has variations. Please select from the available options');
        window.open('./productdetails/' + productid + '/' + this.default_lang, "_top");
        return false;


        this.varientsSelected = [];
        this.variationStr = this.variationIdStr = "";
        this.productdata.Configuration.ProductDetails.variations.forEach((variationData, variationKey) => {
          variationData.values.forEach((variationDataDetails, indexvalue) => {
            if (indexvalue > 0) {
              return true;
            }
            variationDataDetails["VarientType"] = variationData.name;

            // ================== variation selected id string and choice string =======
            if (this.variationStr === "") {
              this.variationStr = variationDataDetails.choice;
              this.variationIdStr = variationDataDetails.id;
            } else {
              this.variationStr = this.variationStr + "," + variationDataDetails.choice;
              this.variationIdStr = this.variationIdStr + "," + variationDataDetails.id;
            }

            this.variation_str = this.variationStr;
            this.variation_id_str = this.variationIdStr;
            // ================== variation selected id string and choice string =======

            this.varientsSelected.push(variationDataDetails);
          });
        });
        this.formProductdetails['variation_id_str'] = this.variation_id_str;
        this.formProductdetails['variation_str'] = this.variation_str;
        this.formProductdetails['SelectedVarient'] = this.varientsSelected;

        const temptotal = this.formProductdetails['total_price'] +
          ((this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']] !== undefined)
            ? parseFloat(this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']].additional_price)
            : 0);

        this.formProductdetails['total_price'] = parseFloat(temptotal).toFixed(2);
        // variation combination productcode and stock_left
        this.formProductdetails['productcode'] =
          (this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']] !== undefined)
            ? this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']].product_code : null;
        this.formProductdetails['stock_left'] =
          (this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']] === undefined) ?
            0 :
            this.productdata.Configuration.ProductDetails.inventory[this.formProductdetails['variation_str']].stock_left;

      }
      // ================end SelectedVarient if any===================================


      // ============== check and setting up the cookie ====================
      const cookieKey = "Cart_items";
      // const existingcartprod = this.cookieService.check(cookieKey) ? JSON.parse(this.cookieService.get(cookieKey)): [];
      const existingcartprod = (localStorage.getItem(cookieKey) != null)
        ? JSON.parse(localStorage.getItem(cookieKey))
        : [];
      const products = existingcartprod.filter((product, key) => {
        if (product.variation_id_str + "-" + product.productid ===
          this.variation_id_str + "-" + this.formProductdetails["productid"]
        ) {
          return false;
        }
        return true;
      });

      products.push(this.formProductdetails);
      //this.cookieService.set(cookieKey, JSON.stringify(products), 24 * 100, "/");
      localStorage.setItem(cookieKey, JSON.stringify(products));
      $(".cartCount").html("(" + products.length + ")");
      //  console.log('after set cookie', JSON.parse(this.cookieService.get(cookieKey)));
      // =============== end check and setting up the cookie ================


      // console.log('from product cat', this.formProductdetails);
      // =====================end creating the cart data ==========================

      // ======= open modal ===============
      $('#instantBuy').modal({
        show: 'true'
      });
      // ========= end open modal ===========
    });
  }
  restoredefault() {
    this.shortProductBy('newest');
  }

  //  new search with autopopulate 
  selectEvent(item, shortby_value) {
    console.log(item.product_name);
    console.log('selectEvent',shortby_value);
    this.search_term = item.product_name;
    this.loading = true;

    this.shortProductBy(shortby_value, item.product_name);
    this.loading = false;
  }
  onChangeSearch(searchby, shortby_value) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.

    this.search_term = searchby;
    //this.loading = true;

    //this.shortProductBy(shortby_value, searchby);
    //this.loading = false;
    // console.log('now ' + searchby);
    // console.log('shortby_value ' + shortby_value);
    // this.searchsuggetion.emit({searchby});
    //this.orderbythis.emit({item,shortby_value});
    console.log('onChangeSearch');

  }

  onFocused(e) {
    // do something
    console.log('onFocused');
  }


  goToProduct(p,e) {
    // $(e.target).closest(".overlay_bg")

  //   var target = e.target || e.srcElement || e.currentTarget;
  //   console.log(target,$(target).css('display'),this.selesp);
  //   if($(target).css('display') == 'none'){
  //     console.log("The paragraph  is hidden.");
  // } else{
  //   console.log("The paragraph  is visiable.");
  // }
  if(this.redirect){
    this.router.navigate(['/productdetails/'+p.id+'/'+this.default_lang]);
  }
    //  
  }


  getStyle(Style1,Style2){
    // console.log(Style1,Style2);

    return Object.assign({}, Style1, Style2);
  }

}
