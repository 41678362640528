import { Component, OnInit, Input, Output } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { environment } from "../../../environments/environment";

declare var $: any;

@Component({
  selector: 'app-instantbuy',
  templateUrl: './instantbuy.component.html',
  styleUrls: ['./instantbuy.component.scss']
})
export class InstantbuyComponent implements OnInit {

  @Input() selectedProductImage: any;
  @Input() selectedProductTitle: any;
  @Input() translatedTextData: any;
  public ogiimgpath= environment.ogiimageendpoint;

  constructor(
    public ru: Router,
    private cookieService: CookieService,
  ) { }

  ngOnInit() {
  }

  visitcart() {
    $('#instantBuy').modal('hide');
  this.ru.navigateByUrl(
    "/cart/" + JSON.parse(this.cookieService.get("defaultlang"))
  );
  }

}
