import { Http } from '@angular/http';
import { Component, OnInit, Input,  Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.scss']
})
export class SpaceComponent implements OnInit {
  @Input() content: any;
  constructor() { }

  ngOnInit() {
  }

}
